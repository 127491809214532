import { useState, useEffect } from 'react';

/**
 * @returns {{width: undefined, height: undefined}}
 */
export default function useWindowDimensions() {
  const [ windowDimensions, setWindowDimensions ] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
