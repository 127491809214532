const reviewSliderStatic = [
  {
    author: 'Derek Hales, Modern Castle',
    title: 'Mila Air Purifier Review',
    content: {
      text: '$1 for knowing more about your indoor air quality '
        + 'and how the current settings of an air purifier can affect that.',
      highlighted: { $1: 'The app is hugely helpful and a great resource' },
    },
  },
  {
    author: 'Nina Huang, Travel + Leisure',
    title: 'Why This Air Purifier Designed by Software Engineers Is the '
      + 'Next Smart Home Gadget You Need',
    content: {
      text: '$1 air purifier that money can buy.',
      highlighted: { $1: 'The smartest, most intuitive, and cutting-edge' },
    },
  },
  {
    author: 'PopSci Commerce Team, Popular Science',
    title: 'Best Air Purifiers: Cleaner Indoor Air At the Push of a Button',
    content: {
      text: 'This classy, app-controllable large room air purifier $1 it’s placed in. '
        + 'It also looks great in any room it’s placed in.',
      highlighted: { $1: 'adapts to the size of whatever room' },
    },
  },
  {
    author: 'Tim Heffernan, Wirecutter',
    title: 'The Best Air Purifier for 2021',
    content: {
      text: 'Versus other smart air purifiers we’ve looked at, $1 above — almost another species.',
      highlighted: { $1: 'its smart capabilities are head-and-shoulders' },
    },
  },
  {
    author: 'John Velasco, Digital Trends',
    title: 'Not All Air Purifiers Protect Against This Silent Killer',
    content: {
      text: 'What astounds me about [Mila] is that $1',
      highlighted: { $1: 'it’s smarter than many other air purifiers I’ve come across.' },
    },
  },
  {
    author: 'Tim Chan & Brandt Raj, Rolling Stone',
    title: 'Best Tech Gifts: 2021',
    content: {
      text: 'Mila\'s air purifier can help remove smoke, dust and pollution from your room, which can $1',
      highlighted: { $1: 'literally make it easier for your partner to sleep at night.' },
    },
  },
  {
    author: 'Larry Olmsted, Forbes',
    title: 'Why You Still Want An Air Purifier Even After Vaccination',
    content: {
      text: 'It offers the $1 I could find at a very reasonable price: more for less.',
      highlighted: { $1: 'best performance, features and filtration' },
    },
  },
  {
    author: 'Christopher Null, TechHive',
    title: 'Mila Air Purifier Review: Breathe Better, and Make a Fashion Statement',
    content: {
      text: 'It’s definitely the $1 we’ve tested, reasonably compact and featuring a '
        + 'polka-dot pattern that wouldn’t look out of place at a MoMA exhibit.',
      highlighted: { $1: 'best-looking air purifier' },
    },
  },
];

export default reviewSliderStatic;
