import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { loadableReady } from '@loadable/component';

import createStore from '../shared/createStore';
import Routes from '../shared/routes';

const state = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const store = createStore(state);

loadableReady(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          {renderRoutes(Routes)}
        </BrowserRouter>
      </HelmetProvider>
    </Provider>,
    document.getElementById('root'),
  );
});
