import React from 'react';
import smilingFaceWithSmilingEyes from 'assets/images/smiling-face-with-smiling-eyes.png';
import shushingFace from 'assets/images/shushing-face.png';
import rocket from 'assets/images/rocket.png';
import faceWithMonocle from 'assets/images/face-with-monocle.png';
import cooking from 'assets/images/cooking.png';
import winkingFace from 'assets/images/winking-face.png';
import loadable from '@loadable/component';

const LazyImg = loadable(() => import('../components/common/LazyImg'));

const firmwareChangeLogStatic = [
  {
    version: '2.1.1',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            A handful of under-the-hood fixes to keep Mila running smoother than ever.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '2.1.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            We updated the touch interface on the Mila display so
            that it’s quicker more responsive.
          </li>
          <li>
            Stay informed with our new outdoor AQI alert feature.
            If the outdoor air quality index exceeds 100,
            you’ll receive an alert on your Mila display.
          </li>
        </ul>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            A handful of under-the-hood fixes to keep Mila running smoother than ever.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '2.0.1',
    time: null,
    prettyTime: null,
    description: <p>Be on the lookout for some fun holiday surprises. 👀 </p>,
  },
  {
    version: '2.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            Your Mila is getting a core upgrade that will pave the way
            for some future software releases (hello HomeKit 🤫).
          </li>
          <li>
            Improved self-calibration features for your VOC readings.
            Your VOC sensors will routinely self-adjust to prevent drift and make
            sure your’e getting the most accurate readings.
          </li>
        </ul>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            Fixed a bug where Mila may get stuck in Diagnostic Mode when booting up.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.7',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>Improvements and Bug Fixes</strong>
        </p>
        <ul>
          <li>
            Enhanced Pressure Readings: We’ve resolved an issue causing
            inaccuracies in Mila’s pressure readings. Our adjustments ensure
            improved accuracy, which also positively affects fan speed and air
            exchange data.
          </li>
          <li>
            Temperature and Humidity Accuracy: Addressed a bug that occasionally
            led to incorrect temperature and humidity readings. With this fix,
            you can trust Mila’s data for a more precise environment overview.
          </li>
          <li>
            Smoother Fan Speed Control: We’ve fine-tuned Mila’s fan speed
            controls by adjusting the driving frequency. This change facilitates
            gradual adjustments, avoiding sudden fan speed changes.
          </li>
          <li>
            Ramp Down Feature: We’ve introduced a ramp down feature to enhance
            fan speed control. Now, fan speed adjustments result in a gradual
            slowdown instead of an abrupt stop.
          </li>
          <li>
            Housekeeper Mode Stability: Fixed a bug that caused random freezes
            or reboots in Housekeeper Mode. Your Mila will now operate more
            reliably in this mode.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.6',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          Firmware 1.6 comes with a whole new set of features and improvements
          to make your Mila experience more seamless.
        </p>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            {' '}
            Added support for WPA3, the latest wireless network security
            protocol.
          </li>
          <li>
            Mila is now easier to spot on your network. You will now see Mila
            listed as
            {' '}
            <strong>mila-air-purifier</strong>
            , followed by the last 6
            digits of your MAC address.
          </li>
          <li>
            Improved self-calibration settings for your VOC readings. Mila will
            routinely self-adjust your VOC sensor to prevent drift and make sure
            you’re getting the most accurate readings.
          </li>
          <li>
            Updated sensor drivers for improved performance from temperature and
            humidity.
          </li>
          <li>Behind-the-scenes updates for improved performance.</li>
          <li>Added support for upcoming app releases.</li>
        </ul>
        <p>
          <strong>Bug fixes</strong>
        </p>
        <ul>
          <li>
            Updated the behavior of Mila’s Energy Save Mode, and priority of how
            screens are shown. Previously, Mila would keep the screen on even
            when Energy Save Mode was activated if there was a humidity alert.
            Mila now takes priority over humidity alerts on the screen when in
            Energy Save Mode, keeping your screen off.
          </li>
          <li>
            Adjusted pressure sensor readings to keep the fan running optimally.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.5.8',
    time: null,
    prettyTime: null,
    description: <p>Be on the lookout for some fun holiday surprises. 😉</p>,
  },
  {
    version: '1.5.7',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            Fixed pressure sensor readings for Milas for more accurate air
            change measurements.
          </li>
          <li>
            We noticed some Milas were not responding to certain modes at the
            correct time so we fixed a time sync issue.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.5.3',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New & Improved Stuff</strong>
        </p>
        <ul>
          <li>
            Improved self-calibration settings specific to VOC readings. While
            you’ll always have the ability to initiate a calibration sequence
            from the app, your Mila will routinely self-adjust its VOC sensor to
            prevent drift and make sure you're getting the most accurate
            readings.
          </li>
          <li>Updated sensor drivers for improved performance.</li>
          <li>
            New filter alerts that will warn you if no filter is detected, if
            you have an overloaded filter, or if it senses that your new filter
            is still sitting in its plastic wrap.
          </li>
          <li>Improved WiFi signal strength for a more stable connection</li>
          <li>
            Backend changes to lay the groundwork for future features.
            <LazyImg src={shushingFace} alt='shushing-face' />
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.4.3',
    time: null,
    prettyTime: null,
    description: (
      <p>
        This update includes some fun new displays for the holidays. Did you
        catch them all?
      </p>
    ),
  },
  {
    version: '1.4.2',
    time: null,
    prettyTime: null,
    description: (
      <p>
        This update includes a couple of bug fixes, including fixing the
        scheduler when bedtimes were set on the hour.
      </p>
    ),
  },
  {
    version: '1.4',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          The 1.4.0 firmware is full of improvements, bug fixes, and nerd stuff
          that lay the foundation for some upcoming features, including ...
        </p>
        <p>
          <strong>New & Improved Stuff</strong>
        </p>
        <ul>
          <li>
            Mila is going global.
            <LazyImg src={rocket} alt='rocket' />
            {' '}
            We made some improvements to
            timezone support configured right into Mila itself.
          </li>
          <li>
            Added some more funsies on the Mila display.
            <LazyImg src={faceWithMonocle} alt='face-with-monocle' />
          </li>
          <li>
            Everyone needs a little recalibration now and again. Your Mila will
            recalibrate it sensors when booting, and when it detects nobody is
            around. This helps make sure your sensor readings remain in tip top
            shape.
          </li>
          <li>
            Improved Mila's Safeguard Alert. Mila was a bit over-protective in
            warning you to close your windows.
          </li>
          <li>
            Optimized memory to allow for faster processing and planned future
            Homekit support.
            <LazyImg src={shushingFace} alt='shushing-face' />
          </li>
        </ul>
        <p>
          <strong>Bugs & Nerd Stuff</strong>
        </p>
        <ul>
          <li>
            Fixed a temperature sensor bug in Energy Save Mode when fans are at
            0 RPM.
          </li>
          <li>
            Support for some upcoming modes like Cooking Alert.
            <LazyImg src={cooking} alt='cooking' />
            {' '}
            <LazyImg src={shushingFace} alt='shushing-face' />
          </li>
          <li>
            Refactored the WiFi driver for reasons most air breathers don't care
            to understand (just know it'll mean better performance
            <LazyImg src={smilingFaceWithSmilingEyes} alt='shushing-face' />
            ).
          </li>
          <li>Improved performance and internal reporting.</li>
        </ul>
      </>
    ),
  },
  {
    version: '1.3.1',
    time: null,
    prettyTime: null,
    description: (
      <p>
        The 1.3.1 firmware adds the necessary support for Eco Mode, which will
        be coming soon to your Mila app.
      </p>
    ),
  },
  {
    version: '1.3.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          It's alive, it's alive!! The 1.3.0 firmware release (and 1.6.0 app)
          activates Mila's positronic brain to provide you deeper insights into
          your home health.
          {' '}
        </p>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            The 1.3 firmware enables the Milabot, giving you the ability to get
            realtime push notifications, track what your Milas are up to, and
            observe the historic logs of your Milas to better spot trends in
            your home air quality.
          </li>
        </ul>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            Improved pairing logic. By popular request, Mila now can search for
            hidden networks and sorts wifi networks by signal strength.
          </li>
          <li>
            Updated VOC, CO and CO2 driver with better temperature and humidity
            compensation, and baseline correction.
          </li>
          <li>
            Update temp and humidity algorithms to better compensate for
            internal device heat and fan convection.
          </li>
          <li>Improved CO alert logic.</li>
        </ul>
        <p>
          <strong>Sneak Peek - What to look forward to next </strong>
          <LazyImg src={shushingFace} alt='shushing-face' />
        </p>
        <p>
          The 1.3 release provides some important updates for some upcoming
          features, including:
        </p>
        <ul>
          <li>Child Lock Feature</li>
          <li>Pollen Alerts</li>
        </ul>
      </>
    ),
  },
  {
    version: '1.2.1',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            Smart modes (Turndown, Sleep, Whitenoise) - we added the logic
            needed to support different settings for level, amplitude and period
            for each of these settings.
          </li>
          <li>We did some refactoring to improve performance and memory.</li>
          <li>
            We added support for changing temperature sensor source via MQTT.
          </li>
          <li>
            We added support for fixing low RPM oscillation. observed in some
            devices.
          </li>
          <li>We added capability to retune ACH controller gains.</li>
        </ul>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>Fixed Sleep mode screen heading typo.</li>
          <li>Fixed RH bug that gave values above 100%.</li>
        </ul>
        <p>
          <strong>Sneak Peek - What to look forward to next </strong>
          <LazyImg src={shushingFace} alt='shushing-face' />
        </p>
        <ul>
          <li>Push notifications</li>
        </ul>
      </>
    ),
  },
  {
    version: '1.2.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            Added a firmware update scheduler to only allow updates during
            daylight hours, so no more bleeps in the night from your Mila.
            <LazyImg
              src={smilingFaceWithSmilingEyes}
              alt='smiling-face-with-smiling-eyes'
            />
          </li>
          <li>Improved the calibration of the relative humidity sensor.</li>
          <li>
            Added the ability to manually calibrate your VOC and CO2 sensor by
            placing your Mila outside or in a room with windows open for 20
            mins.
          </li>
          <li>
            Added a Mold Alert feature to warn you when conditions are right for
            mold growth, and how many days to mold.
          </li>
          <li>
            New holiday themed Easter eggs
            <LazyImg src={winkingFace} alt=' winking-face' />
          </li>
          <li>
            Implemented a PM sensor self diagnostic feature to determine if the
            sensor is being skewed by EMI spikes from poorly grounded HVAC
            systems.
          </li>
        </ul>
        <p>
          <strong>Bug fixes </strong>
        </p>
        <ul>
          <li>
            Fixed night time screen brightness when neither Sleep nor Whitenoise
            are turned on.
          </li>
          <li>
            Fixed screen flickering when switching screens from different tasks.
          </li>
          <li>Faster motion detection.</li>
        </ul>
        <p>
          <strong>Sneak Peek - What to look forward to next </strong>
          <LazyImg src={shushingFace} alt=' shushing-face' />
        </p>
        <ul>
          <li>
            Added logic for personalizing your Sleep, Whitenoise and Turndown
            modes.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.1.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>Improvements</strong>
        </p>
        <ul>
          <li>
            We have completely re-written the WiFi pairing process to be more
            robust. The app will now notify you if you accidentally entered the
            wrong WiFi password and give you more informative messages if you
            miss any steps or run into any issues.
          </li>
          <li>
            We have improved the WiFi signal strength for a more stable
            connection.
          </li>
          <li>We have improved the CO2 and VOC sensor calibration.</li>
        </ul>
        <p>
          <strong>New Features</strong>
        </p>
        <ul>
          <li>
            Mute Toggle - you can now completely mute any beeps and bloops from
            your Mila. You will find a toggle in your app settings under
            Notifications.
          </li>
          <li>
            Placeholder Notification (still Beta'ish) - Mila's sensors can get
            skewed if it's placed right next to your HVAC vent and sees big
            swings in temperature and humidity. Your Mila can now predict this
            situation and notify you to move it a bit further away to improve
            readings.
          </li>
        </ul>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            In conjunction with our new pairing flow on the latest app versions,
            we fixed a critical bug where Mila didn't notify you if you
            accidentally typed in the wrong WiFi password.
          </li>
          <li>
            We fixed some additional WiFi and MQTT bugs to make your connection
            more stable and secure.
          </li>
          <li>
            We fixed a bug in Bubble Boy Mode that could cause fan oscillations
            in certain situations.
          </li>
          <li>
            We fixed a bug in Manual Mode that caused Mila's control system to
            overestimate its time to clean when the AQI was very low.
          </li>
        </ul>
        <p>
          <strong>Sneak Peek - What to look forward to next</strong>
        </p>
        <ul>
          <li>
            This build prepares for the ability to personalize your Sleep Mode
            and White Noise Mode settings. This will be released in the upcoming
            1.3 iOS app and 1.4 Android app.
          </li>
          <li>
            We're preparing a (not so) super secret feature to detect your
            precise filter loading and life remaining.
          </li>
        </ul>
      </>
    ),
  },
];

export default firmwareChangeLogStatic;
