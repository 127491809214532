import { createSlice } from '@reduxjs/toolkit';
import { SUPPORTED_REGIONS } from '../../../shared/enums';

export const initialState = {
  data: {
    region: SUPPORTED_REGIONS.US,
  },
};

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: {},
});

export const selectLocalizationData = state => state.localization.data;

export default localizationSlice.reducer;
