import * as xhrClient from 'axios';
import { getProxyURL } from '../utils';

const baseURL = IS_CLIENT ? '/api' : getProxyURL(PROXY_PROTOCOL, PROXY_HOST, PROXY_PORT);

const axios = xhrClient.create({ baseURL });

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 10000;

export default axios;
