import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import milaInsights from 'assets/images/mila-insights.png';
import milaInsightsWebp from 'assets/images/mila-insights.png?as=webp';
import milaAir3 from 'assets/images/mila-air-3.png';
import milaAir3Webp from 'assets/images/mila-air-3.png?as=webp';
import milaHalo from 'assets/images/mila-halo.png';
import milaHaloWebp from 'assets/images/mila-halo.png?as=webp';
import { STATUS } from '../../shared/enums';
import {
  klaviyoSubscribe,
  selectKlaviyoData,
  selectKlaviyoError,
  selectKlaviyoStatus,
} from '../features/klaviyo/klaviyoSlice';

const ImageLoader = loadable(() => import('../components/common/ImageLoader'));

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */

const products = [
  {
    id: 'mila-insights',
    title: 'Mila Insights',
    subtitle: 'Coming Spring 2024',
    image: {
      defaultUrl: milaInsights,
      webpUrl: milaInsightsWebp,
      alt: 'Mila Insights',
      aspectRatio: '42/21',
    },
  },
  {
    id: 'mila-air-3',
    title: 'Mila Air 3',
    subtitle: 'Coming Summer 2024',
    image: {
      defaultUrl: milaAir3,
      webpUrl: milaAir3Webp,
      alt: 'Mila Air 3',
      aspectRatio: '179/160',
    },
  },
  {
    id: 'mila-halo',
    title: 'Mila Halo',
    subtitle: 'Coming Fall 2024',
    image: {
      defaultUrl: milaHalo,
      webpUrl: milaHaloWebp,
      alt: 'Coming Fall 2024',
      aspectRatio: '182/171',
    },
  },
];

const popupData = [
  {
    youtubeId: '2JRg-gC5oJo',
    title: 'Mila Insights',
    subtitle: 'Coming Spring 2024',
    description:
      'Your home’s air quality unveiled. Mila Insights is the first comprehensive deep-dive into your home’s unique health status, all in simple to understand terms and visuals.',
  },
  {
    youtubeId: '_cXM5pu9Hls',
    title: 'Mila Air 3',
    subtitle: 'Coming Summer 2024',
    description:
      'The smartest air purifier on the market gets an upgrade. Get even higher airflow (CADR) powered by a brand new motor. Experience quieter, more energy efficient use with an updated fan. Plus, get HomeKit compatibility right ouf the gate to seamlessly incorporate Mila Air 3 into your smart home.',
    specifications: {
      head: {
        th1: 'Overview',
        th2: 'values',
      },
      items: [
        {
          label: 'Display',
          value: () => <>LCD w/ capacitive buttons</>,
        },
        {
          label: 'Connectivity',
          value: () => <>WiFi 802.11b/g/n - 2.4GHz</>,
        },
        {
          label: 'Filters',
          value: () => (
            <>
              45 ft
              <sup>2</sup>
              {' '}
              of true HEPA
              <br />
              {' '}
              Up to 1.58 lb of granular activated carbon
            </>
          ),
        },
        {
          label: 'Power',
          value: () => (
            <>
              110-230V AC, 50-60Hz
              <br />
              {' '}
              MAX 1.5A@115V / 0.75A@230V
            </>
          ),
        },
        {
          label: 'Dimensions',
          value: () => (
            <>
              11.8” wide x 15.5” tall
              <br />
              {' '}
              (30 cm wide x 39.4 cm wide)
              <br />
              {' '}
              15 lbs. (6.8 kg)
            </>
          ),
        },
        {
          label: 'Sensors',
          value: () => (
            <>
              PM 1 / 2.5 / 10
              <br />
              {' '}
              Carbon Monoxide
              <br />
              {' '}
              TVOC + eCO2
              <br />
              {' '}
              Radar Proximity Sensor
              <br />
              Relative Temperature and Humidity
            </>
          ),
        },
      ],
    },
  },
  {
    youtubeId: 'sy_-DziLa8A',
    title: 'Mila Halo',
    subtitle: 'Coming Fall 2024',
    description: `A revolutionary humidifier combined with an exceptional air purifier.
    With a sleek design, proprietary water filtration system and thoughtful technology, this isn’t your granny’s humidifier.`,
    specifications: {
      head: {
        th1: 'Overview',
        th2: 'values',
      },
      items: [
        {
          label: 'Display',
          value: () => <>LCD w/ capacitive buttons</>,
        },
        {
          label: 'Connectivity',
          value: () => <>WiFi 802.11b/g/n - 2.4GHz</>,
        },
        {
          label: 'Filters',
          value: () => (
            <>
              Up to 1.58 lb of granular activated carbon
              <br />
              {' '}
              45 ft
              <sup>3</sup>
              {' '}
              of true HEPA
            </>
          ),
        },
        {
          label: 'Power',
          value: () => (
            <>
              110-230V AC, 50-60Hz
              <br />
              {' '}
              MAX 1.5A@115V / 0.75A@230V
            </>
          ),
        },
        {
          label: 'Dimensions',
          value: () => (
            <>
              11.8” wide x 15.5” tall
              <br />
              {' '}
              (30 cm wide x 39.4 cm wide)
              <br />
              {' '}
              15 lbs. (6.8 kg)
            </>
          ),
        },
        {
          label: 'Sensors',
          value: () => (
            <>
              PM 1 / 2.5 / 10
              <br />
              {' '}
              Carbon Monoxide
              <br />
              {' '}
              TVOC + eCO2
              <br />
              {' '}
              Radar Proximity Sensor
              <br />
              Relative Temperature and Humidity
            </>
          ),
        },
      ],
    },
  },
];

const CesPopup = ({
  youtubeId,
  title,
  subtitle,
  description,
  specifications,
  setIsOpen,
  isOpen,
}) => {
  const youtubeConfig = {
    youtube: {
      autoplay: 1,
      controls: 1,
      modestbranding: 0,
      showinfo: 0,
      fs: 1,
      cc_load_policy: 0,
      iv_load_policy: 3,
      autohide: 0,
    },
  };
  const url = `https://www.youtube-nocookie.com/embed/${youtubeId}`;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className='ces-popup-wrapper'
        >
          <span
            className='ces-popup-wrapper__overlay'
            onClick={() => setIsOpen(false)}
          />
          <div className='ces-popup__overflow'>
            <div className='ces-popup__card'>
              <button
                type='button'
                className='ces-popup__close'
                onClick={() => setIsOpen(false)}
                aria-label='popup close'
              >
                <svg className='icon icon-cross'>
                  <use xlinkHref='#icon-cross' />
                </svg>
                <span className='screen-reader-text'>popup close</span>
              </button>
              <div className='ces-popup__video-holder'>
                <ReactPlayer
                  className='ces-popup__iframe'
                  url={url}
                  controls
                  playing
                  width='100%'
                  height='100%'
                  config={youtubeConfig}
                />
              </div>
              <div className='ces-popup__content'>
                <div className='ces-popup__header'>
                  {title && <h2 className='ces-popup__title'>{title}</h2>}
                  {subtitle && (
                    <h3 className='ces-popup__subtitle'>{subtitle}</h3>
                  )}
                  {description && (
                    <div className='ces-popup__description'>
                      <p>{description}</p>
                    </div>
                  )}
                </div>
                {specifications && (
                  <div className='ces-popup__body'>
                    <table className='ces-popup__table'>
                      {specifications.head && (
                        <thead>
                          <tr>
                            {specifications.head.th1 && (
                              <th>{specifications.head.th1}</th>
                            )}
                            {specifications.head.th2 && (
                              <th>
                                <span className='screen-reader-text'>
                                  {specifications.head.th2}
                                </span>
                              </th>
                            )}
                          </tr>
                        </thead>
                      )}
                      {specifications.items && (
                        <tbody>
                          {specifications.items.map(item => (
                            <tr>
                              <td>{item.label}</td>
                              <td>{item.value()}</td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const ProductItem = ({
  idx,
  title,
  subtitle,
  image,
  setIsOpen,
  setCurrentIdx,
}) => {
  const clickHandler = (e, currentIdx) => {
    e.preventDefault();
    setCurrentIdx(currentIdx);
    setIsOpen(true);
  };
  return (
    <div
      className={`ces-hero__item ${idx === 0 ? `ces-hero__item--${idx}` : ''}`}
    >
      <div className='featured-card'>
        <div className='featured-card__media'>
          <ImageLoader
            className='featured-card__image img-relative'
            placeholderAspectRatio={image.aspectRatio}
            image={image.defaultUrl}
            webp={image.webpUrl}
            alt={image.alt}
          />
        </div>
        <div className='featured-card__footer'>
          {title && <h3 className='featured-card__title'>{title}</h3>}
          {subtitle && <p className='featured-card__info'>{subtitle}</p>}
          <button
            type='button'
            className='btn btn--small'
            onClick={e => clickHandler(e, idx)}
            aria-label={`click to read about ${title}`}
          >
            Learn more
          </button>
        </div>
      </div>
    </div>
  );
};

const CESLanding = props => {
  const dispatch = useDispatch();
  const klaviyoData = useSelector(selectKlaviyoData);
  const status = useSelector(selectKlaviyoStatus);
  const error = useSelector(selectKlaviyoError);

  const { route } = props;

  const [ isOpen, setIsOpen ] = React.useState(false);
  const [ currentIdx, setCurrentIdx ] = React.useState(0);

  const settings = {
    autoplay: true,
    speed: 600,
    cssEase: 'cubic-bezier(0.65,0.05,0.36,1)',
    slidesToShow: 3,
    autoplaySpeed: 3000,
    slidesToScroll: 3,
    pauseOnFocus: false,
    arrows: false,
    dots: true,
    gap: 0,
    responsive: [
      {
        breakpoint: 851,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const form = new FormData(event.target);
    const payload = Object.fromEntries(form.entries());
    dispatch(klaviyoSubscribe(payload));
  };

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
        <script src={YOTPO_SRC} async />
      </Helmet>
      <div className='ces-hero'>
        <div className='ces-hero__card'>
          <div className='container'>
            <div className='ces-hero__content'>
              <h1 className='ces-hero__title'>
                Welcome to a
                <br />
                <span>healthier</span>
                {' '}
                home with
                <strong>Mila</strong>
              </h1>
              <Slider className='ces-hero__list' {...settings}>
                {products.map((product, idx) => (
                  <ProductItem
                    key={product.id}
                    idx={idx}
                    {...product}
                    setIsOpen={setIsOpen}
                    setCurrentIdx={setCurrentIdx}
                  />
                ))}
              </Slider>
              <div className='ces-newletter'>
                <form className='newletter-form' onSubmit={handleSubmit}>
                  <div className='newletter-form__group'>
                    <label
                      className='newletter-form__label'
                      htmlFor='newletter-email'
                    >
                      Stay in the know
                    </label>
                    <input
                      id='newletter-email'
                      type='email'
                      name='email'
                      className='newletter-form__input'
                      placeholder='Enter your e-mail address'
                    />
                  </div>
                  <div
                    className={`newletter-form__button ${
                      status === STATUS.LOADING
                        ? 'newletter-form__button--loading'
                        : ''
                    }`}
                  >
                    <button
                      disabled={status === STATUS.LOADING}
                      type='submit'
                      className='btn btn--medium'
                      aria-label='Subscribe now to stay updated'
                    >
                      Subscribe Now
                    </button>
                    {status === STATUS.LOADING && (
                      <svg
                        version='1.1'
                        id='L9'
                        xmlns='http://www.w3.org/2000/svg'
                        x='0px'
                        y='0px'
                        viewBox='0 0 100 100'
                        enableBackground='new 0 0 0 0'
                        xmlSpace='preserve'
                        className='loading'
                      >
                        <path
                          fill='#121616'
                          d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'
                        >
                          <animateTransform
                            attributeName='transform'
                            attributeType='XML'
                            type='rotate'
                            dur='1s'
                            from='0 50 50'
                            to='360 50 50'
                            repeatCount='indefinite'
                          />
                        </path>
                      </svg>
                    )}
                  </div>
                </form>
              </div>
              {(klaviyoData || error) && status !== STATUS.LOADING && (
                <div
                  className={`newsletter-message ${
                    klaviyoData ? 'newsletter-message--success' : ''
                  } ${error ? 'newsletter-message--error' : ''}`}
                >
                  {klaviyoData && klaviyoData.title}
                  {error && error.error}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CesPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        {...popupData[currentIdx]}
      />
    </>
  );
};

export default { component: CESLanding };
