import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';

import FallbackPlaceholder from '../components/common/FallbackPlaceholder';

const BoxContent = loadable(() => import('../components/careers/BoxContent'));
const HeroCareers = loadable(() => import('../components/careers/HeroCareers'));
const GeneralContent = loadable(() => import('../components/careers/GeneralContent'));
const HighlightedBlock = loadable(() => import('../components/careers/HighlightedBlock'));
const JobOpening = loadable(() => import('../components/careers/JobOpening'));
const MediaContent = loadable(() => import('../components/careers/MediaContent'));
const MissionDetail = loadable(() => import('../components/careers/MissionDetail'));
const TitleHeading = loadable(() => import('../components/careers/TitleHeading'));

const Careers = props => {
  const { route } = props;

  const jobOpening = useRef(null);
  const missionDetail = useRef(null);

  const getOffset = el => {
    if (!el.current.getClientRects().length) {
      return { top: 0, left: 0 };
    }

    const rect = el.current.getBoundingClientRect();
    const win = el.current.ownerDocument.defaultView;
    return (
      {
        top: rect.top + win.pageYOffset - 100,
        left: rect.left + win.pageXOffset,
      });
  };

  const scrollToElement = el => {
    const offset = getOffset(el).top;

    window.scrollTo({ behavior: 'smooth', top: offset });
  };

  const scrollToJobOpening = () => {
    scrollToElement(jobOpening);
  };
  const scrollToMissionDetail = () => {
    scrollToElement(missionDetail);
  };

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <HeroCareers fallback={<FallbackPlaceholder />} scrollToJobOpening={scrollToJobOpening} scrollToMissionDetail={scrollToMissionDetail} />
      <TitleHeading />
      <MissionDetail innerRef={missionDetail} />
      <HighlightedBlock />
      <MediaContent />
      <GeneralContent />
      <BoxContent />
      <JobOpening innerRef={jobOpening} />
    </>
  );
};

export default { component: Careers };
