import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
};

/**
 * @type {Slice<{loading: boolean}, {clearFallback: reducers.clearFallback, setFallback: reducers.setFallback}, string>}
 */
export const fallbackSlice = createSlice({
  name: 'fallback',
  initialState,
  reducers: {
    clearFallback: state => {
      state.loading = initialState.loading;
    },
    setFallback: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { clearFallback, setFallback } = fallbackSlice.actions;

export const selectFallbackLoading = state => state.fallback;

export default fallbackSlice.reducer;
