import { createAction, createSlice } from '@reduxjs/toolkit';

/**
 * @type {{errors: *[], isPeacekeeper: boolean}}
 */
export const initialState = {
  errors: [],
  isPeacekeeper: false,
};

export const resetApp = createAction('root/reset');

/**
 * @type {Slice<{errors: *[]}, {addGlobalError: reducers.addGlobalError, setIsPeacekeeper: reducers.setIsPeacekeeper}, string>}
 */

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    addGlobalError: (state, action) => {
      state.errors.push(action.payload);
    },
    setIsPeacekeeper: (state, action) => {
      state.isPeacekeeper = action.payload;
    },
  },
});

export const { addGlobalError, setIsPeacekeeper } = rootSlice.actions;

export const selectErrors = state => state.root.errors;
export const selectIsPeacekeeper = state => state.root.isPeacekeeper;

export default rootSlice.reducer;
