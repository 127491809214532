import baby from 'assets/images/baby.png';
import sneezingFace from 'assets/images/sneezing-face.png';
import construction from 'assets/images/construction.png';
import dogFace from 'assets/images/dog-face.png';
import woman from 'assets/images/woman.png';
import leafFlutteringInWind from 'assets/images/leaf-fluttering-in-wind.png';
import explodingHead from 'assets/images/exploding-head.png';

const heroFilterStatic = [
  {
    title: 'A filter for every',
    animateLetter: 'Critter Cuddler.',
    color: '#F42D53',
    image: {
      src: dogFace,
      alt: 'dog face',
    },
  },
  {
    title: 'A filter for every',
    animateLetter: 'Home Wrecker.',
    color: '#F27E0F',
    image: {
      src: construction,
      alt: 'Construction',
    },
  },
  {
    title: 'A filter for every',
    animateLetter: 'Basic Breather.',
    color: '#3E4649',
    image: {
      src: leafFlutteringInWind,
      alt: 'Leaf fluttering in wind',
    },
  },
  {
    title: 'A filter for every',
    animateLetter: 'Big Sneeze.',
    color: '#FFC140',
    image: {
      src: sneezingFace,
      alt: 'Sneezing face',
    },
  },
  {
    title: 'A filter for every',
    animateLetter: 'Overreactor.',
    color: '#0A56EA',
    image: {
      src: explodingHead,
      alt: 'Exploding head',
    },
  },
  {
    title: 'A filter for every',
    animateLetter: 'Mama-to-Be.',
    color: '#1CE4D5',
    image: {
      src: woman,
      alt: 'woman',
    },
  },
  {
    title: 'A filter for every',
    animateLetter: 'Rookie Parent.',
    color: '#8852F6',
    image: {
      src: baby,
      alt: 'baby',
    },
  },
];

export default heroFilterStatic;
