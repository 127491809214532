import React from 'react';
import { Helmet } from 'react-helmet-async';
import workspace from 'assets/images/workspace.png';
import spiralCalendar from 'assets/images/spiral-calendar.png';
import appSlider from 'assets/images/app-slider.mp4';
import columnImage7 from 'assets/images/column-image7.png';
import columnImage5_1 from 'assets/images/column-image5_1.png';
import handshake from 'assets/images/handshake.png';
import LazyLoad from 'react-lazyload';
import loadable from '@loadable/component';

const FadeIn = loadable(() => import('../components/common/FadeIn'));

const Conciairge = props => {
  const { route } = props;

  const initPopupWidget = e => {
    e.preventDefault();
    Calendly.initPopupWidget({ url: 'https://calendly.com/mila-marketing/get-to-know-mila' });
    return false;
  };

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
        <link href='https://assets.calendly.com/assets/external/widget.css' rel='stylesheet' />
        <script
          src='https://assets.calendly.com/assets/external/widget.js'
          type='text/javascript'
          async
        />
      </Helmet>
      <section className='hero hero--multicolor-heading'>
        <div className='container'>
          <h1 className='hero__title'>
            Welcome to the Mila
            <strong>
              Conci
              <span>air</span>
              ge
            </strong>
          </h1>
          <img src={workspace} alt='workspace' />
        </div>
      </section>

      <section className='appointment-block'>
        <div className='container'>
          <div className='appointment-block__inner'>
            <div className='appointment-block__content'>
              <p>
                Book
                {' '}
                <img src={spiralCalendar} alt='spiral calendar icon' />
                {' '}
                a personal appointment with the Mila Conciairge.
                {' '}
              </p>
            </div>
            <button type='button' className='btn btn--large' onClick={initPopupWidget}>
              Book My Appointment
            </button>
          </div>
        </div>
      </section>
      <section className='overlapping-block'>
        <div className='container'>
          <h2 className='overlapping-block__section-title'>During your appointment ...</h2>
          <div className='overlapping-block__wrap'>
            <div className='overlapping-block__item'>
              <figure className='overlapping-block__image'>
                <LazyLoad>
                  <FadeIn>
                    <img
                      src={columnImage5_1}
                      alt='Mila white box'
                    />
                  </FadeIn>
                </LazyLoad>
              </figure>
              <div className='overlapping-block__content'>
                <h2 className='overlapping-block__title'>Get a closer look at Mila</h2>
                <div className='overlapping-block__description'>
                  <p>
                    Check out Mila from every angle and get a sense for how it’ll look in your
                    home.
                  </p>
                </div>
              </div>
            </div>

            <div className='overlapping-block__item'>
              <figure className='overlapping-block__video'>
                <video
                  id='overlapping-block__slider-video'
                  playsInline
                  webkit-playsinline='true'
                  autoPlay
                  muted
                  loop
                >
                  <source src={appSlider} type='video/mp4' />
                </video>
              </figure>
              <div className='overlapping-block__content'>
                <h2 className='overlapping-block__title'>See a live demo</h2>
                <div className='overlapping-block__description'>
                  <p>
                    We’ll show you Mila’s simple setup, how to change the filter, and answer all
                    your q’s.
                  </p>
                </div>
              </div>
            </div>

            <div className='overlapping-block__item'>
              <figure className='overlapping-block__image'>
                <LazyLoad>
                  <FadeIn>
                    <img
                      src={columnImage7}
                      alt='Collection of mila boxes'
                    />
                  </FadeIn>
                </LazyLoad>
              </figure>
              <div className='overlapping-block__content'>
                <h2 className='overlapping-block__title'>Find your filter</h2>
                <div className='overlapping-block__description'>
                  <p>
                    Get help deciding which of our 7 filters is just right for you or your
                    people.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='overlapping-block__btn-wrap'>
            <a className='btn btn--large' onClick={initPopupWidget}>
              Book My Appointment
            </a>
          </div>
        </div>
      </section>
      <section className='content-block content-block--alt'>
        <div className='container'>
          <div className='content-block__intro'>
            <h2 className='content-block__intro-title'>100% Mila-backed guarantee.</h2>
            <figure className='content-block__image'>
              <LazyLoad>
                <FadeIn>
                  <img src={handshake} alt=' handshake' />
                </FadeIn>
              </LazyLoad>
            </figure>
            <div className='content-block__intro-description'>
              <p>
                At Mila we feel incredibly lucky to be able to make products that have a
                meaningful impact on the health and wellbeing of the people we love. We take that
                responsibility seriously.
              </p>
            </div>
          </div>
          <div className='content-block__main'>
            <div className='content-block__description'>
              <p>That's why we provide our Mila-backed guarantee, including:</p>
            </div>
            <div className='content-block__card-wrap'>
              <div className='content-block__card'>
                <h3 className='content-block__card-title'>30-Day Refunds</h3>
                <p className='content-block__card-description'>
                  If you don’t love Mila, you can
                  return her within 30 days for a full refund, minus shipping and handling.
                </p>
              </div>
              <div className='content-block__card'>
                <h3 className='content-block__card-title'>One-Year Warranty</h3>
                <p className='content-block__card-description'>
                  Should something happen with your
                  Mila, no worries—you’re covered.
                </p>
              </div>
              <div className='content-block__card'>
                <h3 className='content-block__card-title'>The Mila Promise</h3>
                <p className='content-block__card-description'>
                  Honestly clean air from an honest
                  company.
                  {' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default { component: Conciairge };
