import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import appleStore from 'assets/images/apple-store.svg';
import googlePlayStore from 'assets/images/google-play-store.svg';
import UpdateHeroImg from 'assets/images/update-hero-img.png';
import UpdateHeroImgWebp from 'assets/images/update-hero-img.png?as=webp';
import appUpdateImg01 from 'assets/images/app-update-img-01.png';
import appUpdateImg01Webp from 'assets/images/app-update-img-01.png?as=webp';
import appUpdateImg02 from 'assets/images/app-update-img-02.png';
import appUpdateImg02Webp from 'assets/images/app-update-img-02.png?as=webp';
import appUpdateImg03 from 'assets/images/app-update-img-03.png';
import appUpdateImg03Webp from 'assets/images/app-update-img-03.png?as=webp';
import appUpdateImg04 from 'assets/images/app-update-img-04.png';
import appUpdateImg04Webp from 'assets/images/app-update-img-04.png?as=webp';
import appUpdateImg05 from 'assets/images/app-update-img-05.png';
import appUpdateImg05Webp from 'assets/images/app-update-img-05.png?as=webp';
import {
  fetchRegionalPrices,
  selectRegionalPricesData, selectRegionalPricesError,
  selectRegionalPricesStatus,
} from '../features/regionalPrices/regionalPricesSlice';
import { STATUS, SUPPORTED_REGIONS } from '../../shared/enums';
import { selectLocalizationData } from '../features/localization/localizationSlice';

const OrderBlockAlt = loadable(() => import('../components/app-update/OrderBlockAlt'));
const LazyImg = loadable(() => import('../components/common/LazyImg'));
const FadeIn = loadable(() => import('../components/common/FadeIn'));

/**
 * @param settings
 * @returns {JSX.Element}
 * @constructor
 */

const UpdateFeaturesGrid = ({ settings }) => (
  <Slider {...settings} className='update-features__grid'>
    <div className='update-features__item'>
      <div className='feature-card'>
        <div className='feature-card__icon'>
          <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='26.4353' cy='25.5134' r='25.5134' fill='#9A9A9A' />
            <path
              d='M42.2381 20.4286L33.8571 9L18 13L11 21.9524V34.1429L25.4762 41L42.2381 33.381V20.4286Z'
              fill='white'
              fillOpacity='0.2'
              stroke='white'
              strokeWidth='3'
              strokeLinejoin='round'
            />
            <path
              d='M11 21.9525L26.2381 28.8096L43 21.1906'
              stroke='white'
              strokeWidth='3'
              strokeLinejoin='round'
              strokeDasharray='4 4'
            />
            <path
              d='M26.9944 28.8095L18.5 13L33.8516 9L42.2325 20.4286V33.381L26.2325 41L26.9944 28.8095Z'
              fill='white'
              fillOpacity='0.2'
            />
          </svg>
        </div>
        <h3 className='feature-card__title'>Indoor Air Quality (24 hours)</h3>
        <div className='feature-card__detail'>
          <p>Get a full-day look at how the air’s been behaving inside.</p>
        </div>
      </div>
    </div>
    <div className='update-features__item'>
      <div className='feature-card'>
        <div className='feature-card__icon'>
          <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='25.7947' cy='25.5134' r='25.5134' fill='#9A9A9A' />
            <path
              d='M24.45 31C28.09 20.7806 23.1667 12.3387 21 9.5L28.1978 9L32.9978 11.5L35.9978 17.5L36.9978 23L34.0478 27.4516L29.4978 31H24.45Z'
              fill='white'
              fillOpacity='0.3'
            />
            <circle
              cx='25.5'
              cy='20.5'
              r='11'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M26 16V41'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M22 42H30'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M21 22L26 28'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <h3 className='feature-card__title'>Outdoor Air Quality (24 hours)</h3>
        <div className='feature-card__detail'>
          <p>Dive into a full-day view of the air quality outside.</p>
        </div>
      </div>
    </div>
    <div className='update-features__item'>
      <div className='feature-card'>
        <div className='feature-card__icon'>
          <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='25.5134' cy='25.5134' r='25.5134' fill='#9A9A9A' />
            <path
              d='M40.5057 33.7598C37.6917 39.3098 31.9317 43.1135 25.2833 43.1135C15.8631 43.1135 8.22656 35.477 8.22656 26.0568C8.22656 16.6366 15.8631 9 25.2833 9C32.8476 9 39.2617 13.9239 41.4956 20.7413'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M43.8594 36.0881L40.9639 31.146L35.7773 33.647'
              stroke='white'
              strokeWidth='2.7'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M19.3235 32H30V23.7561L24.5 19L19 23.7561L19.3235 32Z'
              fill='#C0C0C0'
              stroke='white'
              strokeWidth='2'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <h3 className='feature-card__title'>Comprehensive Indoor View</h3>
        <div className='feature-card__detail'>
          <p>Consult this superwidget for complete stats on your indoor air situation.</p>
        </div>
      </div>
    </div>
    <div className='update-features__item'>
      <div className='feature-card'>
        <div className='feature-card__icon'>
          <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='25.5134' cy='25.5134' r='25.5134' fill='#9A9A9A' />
            <path
              d='M39.6914 32.3082C37.0423 37.5328 31.62 41.1135 25.3614 41.1135C16.4935 41.1135 9.30469 33.9247 9.30469 25.0568C9.30469 16.1889 16.4935 9 25.3614 9C32.4822 9 38.5203 13.6352 40.6232 20.053'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M26 33.5725L26 25.5725'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M17.2104 23.9767L17.0699 23.6004L17.5801 23.5147C20.9631 22.9465 24.2462 24.9628 25.2696 28.237C21.8914 29.0674 18.4267 27.2359 17.2104 23.9767Z'
              fill='white'
              fillOpacity='0.3'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M31.9121 19.1784L31.9303 18.7771L31.4184 18.8521C28.0243 19.3492 25.5181 22.2753 25.5484 25.7055C29.0184 25.4594 31.754 22.6535 31.9121 19.1784Z'
              fill='white'
              fillOpacity='0.3'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <h3 className='feature-card__title'>Comprehensive Outdoor View</h3>
        <div className='feature-card__detail'>
          <p>Geek out on a megaview of outdoor highlights and trends.</p>
        </div>
      </div>
    </div>
  </Slider>
);

/**
 * @param settings
 * @returns {JSX.Element}
 * @constructor
 */
const UpdateFeaturesGrid2 = ({ settings }) => (
  <Slider {...settings} className='update-features__grid'>
    <div className='update-features__item'>
      <div className='feature-card'>
        <div className='feature-card__icon'>
          <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='26.4353' cy='25.5134' r='25.5134' fill='#9A9A9A' />
            <path
              d='M42.2381 20.4286L33.8571 9L18 13L11 21.9524V34.1429L25.4762 41L42.2381 33.381V20.4286Z'
              fill='white'
              fillOpacity='0.2'
              stroke='white'
              strokeWidth='3'
              strokeLinejoin='round'
            />
            <path
              d='M11 21.9525L26.2381 28.8096L43 21.1906'
              stroke='white'
              strokeWidth='3'
              strokeLinejoin='round'
              strokeDasharray='4 4'
            />
            <path
              d='M26.9944 28.8095L18.5 13L33.8516 9L42.2325 20.4286V33.381L26.2325 41L26.9944 28.8095Z'
              fill='white'
              fillOpacity='0.2'
            />
          </svg>
        </div>
        <h3 className='feature-card__title'>Indoor Air Quality (12 hours)</h3>
        <div className='feature-card__detail'>
          <p>Get a quick peek at your indoor air quality any time of day.</p>
        </div>
      </div>
    </div>
    <div className='update-features__item'>
      <div className='feature-card'>
        <div className='feature-card__icon'>
          <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='25.7947' cy='25.5134' r='25.5134' fill='#9A9A9A' />
            <path
              d='M24.45 31C28.09 20.7806 23.1667 12.3387 21 9.5L28.1978 9L32.9978 11.5L35.9978 17.5L36.9978 23L34.0478 27.4516L29.4978 31H24.45Z'
              fill='white'
              fillOpacity='0.3'
            />
            <circle
              cx='25.5'
              cy='20.5'
              r='11'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M26 16V41'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M22 42H30'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M21 22L26 28'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <h3 className='feature-card__title'>Outdoor Air Quality (12 hours)</h3>
        <div className='feature-card__detail'>
          <p>Grab a quick snapshot of recent air quality outside.</p>
        </div>
      </div>
    </div>
    <div className='update-features__item'>
      <div className='feature-card'>
        <div className='feature-card__icon'>
          <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='25.5134' cy='25.5134' r='25.5134' fill='#9A9A9A' />
            <path d='M18 37.5V23.5' stroke='white' strokeWidth='3' strokeLinecap='round' />
            <path d='M26 37.5V15' stroke='white' strokeWidth='3' strokeLinecap='round' />
            <path d='M34 37.5V29.5' stroke='white' strokeWidth='3' strokeLinecap='round' />
            <path
              d='M25 28.0001C21 25.2001 18.3333 33.1668 17.5 37.5001L18 39H26H34L34.5 30C32.5 22 30.6667 24 30 26C29.5 28 29 30.8001 25 28.0001Z'
              fill='white'
              fillOpacity='0.3'
            />
          </svg>
        </div>
        <h3 className='feature-card__title'>Weekly Outdoor AQI Trends</h3>
        <div className='feature-card__detail'>
          <p>See how your outdoor air quality looked over the last 7 days.</p>
        </div>
      </div>
    </div>
    <div className='update-features__item'>
      <div className='feature-card'>
        <div className='feature-card__icon'>
          <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='25.5134' cy='25.5134' r='25.5134' fill='#9A9A9A' />
            <circle cx='25' cy='26' r='10.5' stroke='white' strokeWidth='3' />
            <path d='M25 11V15' stroke='white' strokeWidth='3' strokeLinecap='round' />
            <path d='M41 26L36 26' stroke='white' strokeWidth='3' strokeLinecap='round' />
            <path
              d='M37.125 19L34.3271 20.6154'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M32 14L30.6659 16.1331'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M33 39L30.3846 35.3266'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M36.5859 33.2783L33.788 31.6629'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path d='M25 37V40' stroke='white' strokeWidth='3' strokeLinecap='round' />
            <path d='M14 26L11 26' stroke='white' strokeWidth='3' strokeLinecap='round' />
            <path
              d='M15.6641 31.3848L13 33'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M19.9062 35.7878L18 39'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M19.6094 16.6738L17.994 13.8759'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M15.125 20.8936L11 18'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M19 34.5C31.8 34.1 31.6667 23.3333 30 18L35.5 22.5V30.5L30 36.5H23.5L19 34.5Z'
              fill='white'
              fillOpacity='0.3'
            />
          </svg>
        </div>
        <h3 className='feature-card__title'>Allergen Levels</h3>
        <div className='feature-card__detail'>
          <p>Check what current allergen levels are around you at any given moment.</p>
        </div>
      </div>
    </div>
  </Slider>
);

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AppUpdate = props => {
  const { route } = props;

  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const regionalPrices = useSelector(selectRegionalPricesData);
  const status = useSelector(selectRegionalPricesStatus);
  const error = useSelector(selectRegionalPricesError);
  const [ isImgLoading, setIsImgLoading ] = useState(false);
  const [ awaitImgLoad, setAwaitImgLoad ] = useState(false);
  const isRegionMatch = localization.region === SUPPORTED_REGIONS.CA;

  useEffect(() => {
    if (status !== STATUS.PREFETCHED) {
      dispatch(fetchRegionalPrices(localization));
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (!isImgLoading) return;
    const timer = setTimeout(() => {
      setAwaitImgLoad(true);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [ isImgLoading ]);

  const settings = {
    slidesToShow: 4,
    slideToScroll: 1,
    arrows: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [{
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
      },
    }, {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
      },
    }, {
      breakpoint: 641,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        initialSlide: 2,
      },
    }],
  };

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <div className='update-hero'>
        <div className='container container--small'>
          <div className='update-hero__content'>
            <h1 className='update-hero__title'>Update 1.13 is lit 🔥</h1>
            <div className='update-hero__description'>
              <p>
                New iOS widgets and a new mode—with my mind on my Mila and my Mila on my
                mind.
              </p>
            </div>
            <div className='update-hero__buttons'>
              <a
                href={`${isRegionMatch ? 'https://apps.apple.com/ca/app/mila-air/id1520776049' : 'https://apps.apple.com/us/app/mila-air/id1520776049'}`}
                target='_blank'
                className='btn btn--dark-gray'
                rel='noreferrer'
              >
                <LazyImg
                  className='btn__apple-icon'
                  src={appleStore}
                  alt='App Store'
                />
                App Store
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.mila.androidv2'
                target='_blank'
                className='btn btn--white'
                rel='noreferrer'
              >
                <LazyImg
                  src={googlePlayStore}
                  alt='Google Play Store'
                />
                Google Play
              </a>
            </div>
          </div>
          <figure className='update-hero__media img-relative'>
            {!awaitImgLoad
              && <div className='image-placeholder' style={{ aspectRatio: `${512 / 668}` }} />}
            <LazyLoad className={`${!awaitImgLoad ? 'img-absolute' : ''}`}>
              <FadeIn>
                <picture>
                  <source srcSet={UpdateHeroImgWebp} type='image/webp' />
                  <source srcSet={UpdateHeroImg} type='image/png' />
                  <img
                    src={UpdateHeroImg}
                    alt='App updated info'
                    onLoad={() => setIsImgLoading(true)}
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
        </div>
      </div>
      <section className='update-info update-info--default update-info--01'>
        <div className='container'>
          <div className='update-info__grid'>
            <div className='update-info__content'>
              <h2 className='update-info__title'>It’s not widgetsman. It’s widgets, man.</h2>
              <div className='update-info__description'>
                <p>
                  Calling all iOS users, this release brings your favorite app right to your
                  iPhone home screen and Today view—so you can nerd out on your air quality stats
                  in one quick glance. 🤓
                </p>
                <p>
                  Choose from a selection of cute widgets to get an instant view of your most
                  need-to-know air quality trends including real-time indoor air quality, outdoor
                  air quality, and pollen levels. Go with small, medium, or large widgets
                  depending on your own level of air quality obsession.
                </p>
              </div>
            </div>
            <figure className='update-info__media'>
              <LazyLoad>
                <FadeIn>
                  <picture>
                    <source srcSet={appUpdateImg01Webp} type='image/webp' />
                    <source srcSet={appUpdateImg01} type='image/png' />
                    <img src={appUpdateImg01} alt='It’s not widgetsman. It’s widgets, man.' />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </figure>
          </div>
        </div>
      </section>
      <section className='update-features'>
        <div className='container'>
          <div className='update-features__header'>
            <h2 className='update-features__title'>The Little Widgets</h2>
            <div className='update-features__description'>
              <p>Small, digestible bites of data in one quick glance.</p>
            </div>
          </div>
          <UpdateFeaturesGrid2 settings={settings} />
        </div>
      </section>
      <section className='update-features update-features--alt'>
        <div className='container'>
          <div className='update-features__header'>
            <h2 className='update-features__title'>Medium & Big-Boy Widgets</h2>
            <div className='update-features__description'>
              <p>For a more nerdy look at what’s going on.</p>
            </div>
          </div>
          <UpdateFeaturesGrid settings={settings} />
        </div>
      </section>
      <section className='update-info update-info--default update-info--02'>
        <div className='container'>
          <div className='update-info__grid'>
            <div className='update-info__content'>
              <h2 className='update-info__title'>Assign a widget to every ‘hood. </h2>
              <div className='update-info__description'>
                <p>
                  Lastly, for those hardcore Miliebers out there with Milas across multiple
                  locations (like a vacation home or office), you’ll also have the ability to set
                  a location for each widget. Which opens up even more fun data comparisons. Enjoy
                  that rabbit hole and if you have any requests for additional widgets, let us
                  know. 🙌
                </p>
              </div>
            </div>
            <figure className='update-info__media'>
              <LazyLoad>
                <FadeIn>
                  <picture>
                    <source srcSet={appUpdateImg02Webp} type='image/webp' />
                    <source srcSet={appUpdateImg02} type='image/png' />
                    <img src={appUpdateImg02} alt='Assign a widget to every ‘hood.' />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </figure>
          </div>
        </div>
      </section>
      <section className='update-info update-info--reverse update-info--03'>
        <div className='container'>
          <div className='update-info__grid'>
            <div className='update-info__content'>
              <h2 className='update-info__title'>How widgets work 👀</h2>
              <div className='update-info__description'>
                <p>New to widgets? Here’s what you need to know:</p>
                <p>
                  <strong>To add a Mila widget to your Home Screen: </strong>
                  Simply touch and hold an empty part of your Home Screen background until the
                  apps begin to jiggle. Then press the “+” button at the top left of the screen to
                  open the widget gallery. Select the type and size of widget you want and tap Add
                  Widget.
                </p>

                <p>
                  Add them to your Today view by swiping left on your Home Screen and tapping the
                  “Edit” button.
                  {' '}
                </p>
                <p>
                  <strong>To configure a widget’s location:</strong>
                  You can change the location assigned to the widget by holding the widget and
                  tapping “Configure.”
                </p>
                <p>
                  <strong>To remove a widget:</strong>
                  Touch and hold the widget you want to remove. Then tap “Remove Widget.” 👍
                </p>
              </div>
            </div>
            <figure className='update-info__media'>
              <LazyLoad>
                <FadeIn>
                  <picture>
                    <source srcSet={appUpdateImg03Webp} type='image/webp' />
                    <source srcSet={appUpdateImg03} type='image/png' />
                    <img src={appUpdateImg03} alt='How widgets work 👀' />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </figure>
          </div>
        </div>
      </section>
      <section className='update-info update-info--default update-info--04'>
        <div className='container'>
          <div className='update-info__grid'>
            <div className='update-info__content'>
              <h2 className='update-info__title'>High vibin’ with a new mode.</h2>
              <div className='update-info__description'>
                <p>
                  On a chill note, we were inspired by a slew of community requests to bring you
                  something new: Chronic Mode. If it’s not your thing, feel free to pass. But for
                  those that are into it, you can activate this mode by putting a room under
                  “Music Room.” Mila will go into overdrive to clear the sticky icky from the air.
                  ✌️
                </p>
              </div>
            </div>
            <figure className='update-info__media'>
              <LazyLoad>
                <FadeIn>
                  <picture>
                    <source srcSet={appUpdateImg04Webp} type='image/webp' />
                    <source srcSet={appUpdateImg04} type='image/png' />
                    <img src={appUpdateImg04} alt='High vibin’ with a new mode.' />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </figure>
          </div>
        </div>
      </section>
      <section className='podcast'>
        <div className='podcast__card'>
          <div className='podcast__grid'>
            <figure className='podcast__media'>
              <LazyLoad>
                <FadeIn>
                  <picture>
                    <source srcSet={appUpdateImg05Webp} type='image/webp' />
                    <source srcSet={appUpdateImg05} type='image/png' />
                    <img src={appUpdateImg05} alt='Plus a lil’ playlist.' />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </figure>
            <div className='podcast__content'>
              <h2 className='podcast__title'>Plus a lil’ playlist. 🎧</h2>
              <div className='podcast__description'>
                <p>
                  To celebrate our new mode release, here’s a little audio to match the vibe.
                  Turn it up, turn Mila on, and kick alllll the way back.
                </p>
              </div>
              <p className='podcast__highlight-description'>
                Listen to
                {' '}
                <a
                  href='https://open.spotify.com/playlist/4Fxf7ApwOio18hsV9Ds7DT'
                  className='podcast__link'
                  target='_blank'
                  rel='noreferrer'
                >
                  Straight Chillin’
                  with Mila
                </a>
                {' '}
                on Spotify.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='update-cfa'>
        <div className='container'>
          <h2 className='update-cfa__title'>
            And it’s always getting smarter.
          </h2>
          <div className='update-cfa__description'>
            <p>
              The Mila app is never finished. We’re always improving and providing more
              resources to help rid the world of airblivion. Have an idea of your own? Don’t
              hesitate to
              {' '}
              <a href='mailto:support@milacares.com'>let us know</a>
              —we’re listening.
            </p>
          </div>
          <h3 className='update-cfa__subtitle'>
            Check out the latest
            {' '}
            <br />
            updates.
          </h3>
          <Link to='/support' className='update-cfa__link'>Visit our change log</Link>
        </div>
      </section>
      {regionalPrices && (
        <OrderBlockAlt
          error={error}
          localization={localization}
          regionalPrices={regionalPrices}
          title='Get Mila (and the awesome app) today.'
          description={`She’s backed by a 30-day, no-questions-asked guarantee. You’ve got nothing to
           lose but crappy air. Order your Mila today.`}
          altStyle='order-alt--style2'
        />
      )}
    </>
  );
};
const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchRegionalPrices(data),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default { component: AppUpdate, loadData };
