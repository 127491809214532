import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { FILTER_NAMES, FILTER_TYPES } from '../../shared/enums';
import { selectLocalizationData } from '../features/localization/localizationSlice';

const ProductHero = loadable(() => import(/* webpackPrefetch: true */ '../components/product-landing/ProductHero'));
const ProductOrder = loadable(() => import('../components/product-landing/ProductOrder'));
const ProductFeatures = loadable(() => import('../components/product-landing/ProductFeatures'));
const ProductReviews = loadable(() => import('../components/product-landing/ProductReviews'));
const ProductFilter = loadable(() => import('../components/product-landing/ProductFilter'));
const ProductStats = loadable(() => import('../components/product-landing/ProductStats'));
const ProductPerformanceStats = loadable(() => import('../components/product-landing/ProductPerformanceStats'));
const ContentSlider = loadable(() => import('../components/product-landing/ContentSlider'));
const ProductTestimonial = loadable(() => import('../components/product-landing/ProductTestimonial'));
const ProductOrderAlt = loadable(() => import('../components/product-landing/ProductOrderAlt'));
const ProductFaqs = loadable(() => import('../components/product-landing/ProductFaqs'));
/**
  * @param props
 * @return {JSX.Element}
 * @constructor
 */

const ProductLanding = props => {
  const {
    match: {
      params: { type },
    },
  } = props;

  const localization = useSelector(selectLocalizationData);

  const page = Object.keys(FILTER_TYPES)
    .find(key => FILTER_TYPES[key] === type);

  return (
    <>
      <Helmet>
        <title>
          Mila Air Purifier:
          {' '}
          {FILTER_NAMES[page] || FILTER_NAMES.BB}
        </title>
      </Helmet>
      <ProductHero />
      <ProductOrder type={page} localization={localization} />
      <ProductFeatures type={page} localization={localization} />
      <ProductReviews />
      <ProductFilter />
      <ProductStats type={page} localization={localization} />
      <ProductPerformanceStats type={page} />
      <ContentSlider />
      <ProductTestimonial />
      <ProductOrderAlt type={page} localization={localization} />
      <ProductFaqs type={page} localization={localization} />
    </>
  );
};

export default {
  component: ProductLanding,
};
