import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import {
  fetchRegionalPrices,
  selectRegionalPricesStatus,
} from '../features/regionalPrices/regionalPricesSlice';
import {
  fetchExchangeRates,
  selectExchangeRatesStatus,
} from '../features/exchangeRates/exchangeRatesSlice';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import { STATUS } from '../../shared/enums';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';

const HeroBlock = loadable(() => import(/* webpackPrefetch: true */ '../components/performance/HeroBlock'));
const ColumnContent = loadable(() => import('../components/performance/ColumnContent'));
const FullwidthContent = loadable(() => import('../components/performance/FullwidthContent'));
const OrderBlock = loadable(() => import('../components/common/OrderBlock'));
const LeaderBlock = loadable(() => import('../components/performance/LeaderBlock'));
const ComparisonBlock = loadable(() => import('../components/performance/ComparisonBlock'));
const ComparisonBar = loadable(() => import('../components/performance/ComparisonBar'));
const CarouselBlock = loadable(() => import('../components/performance/CarouselBlock'));

/**
 * @returns {JSX.Element}
 * @constructor
 */

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
const Performance = props => {
  const { route } = props;
  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const regionalPricesStatus = useSelector(selectRegionalPricesStatus);
  const exchangeRatesStatus = useSelector(selectExchangeRatesStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  useEffect(() => {
    if (![ PREFETCHED, SUCCEEDED ].includes(regionalPricesStatus)) {
      dispatch(fetchRegionalPrices(localization));
    }
    if (![ PREFETCHED, SUCCEEDED ].includes(exchangeRatesStatus)) {
      dispatch(fetchExchangeRates());
    }
    return undefined;
  }, []);

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <HeroBlock fallback={<FallbackPlaceholder />} />
      <ColumnContent />
      <FullwidthContent />
      <LeaderBlock />
      {([ PREFETCHED, SUCCEEDED ].includes(regionalPricesStatus)
          && [ PREFETCHED, SUCCEEDED ].includes(exchangeRatesStatus)) && (
        <ComparisonBlock />
      )}
      <ComparisonBar />
      <CarouselBlock />
      {[ PREFETCHED, SUCCEEDED ].includes(regionalPricesStatus) && (<OrderBlock />)}
    </>
  );
};

const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchRegionalPrices(data),
    fetchExchangeRates(),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default { component: Performance, loadData };
