import App from '../client/App';

import {
  AppUpdate,
  Careers,
  Conciairge,
  Filters,
  FilterStandaloneWraper,
  FilterWrapper,
  Gottcha,
  Landing,
  NotFound,
  OurStory,
  Peacekeeper,
  Performance,
  Referral,
  StandWithUkraine,
  Support,
  TheWallOfLove,
  WhyMila,
  WhyMilaLanding,
  AppPage,
  CESLanding,
  AccessibilityPolicy,
  ProductLanding,
  Warranty,
} from '../client/pages';

export default [
  {
    ...App,
    routes: [
      {
        ...Landing,
        path: '/',
        exact: true,
        meta: {
          title: 'Mila Cares',
        },
      },
      {
        ...Performance,
        path: '/performance',
        exact: true,
        meta: {
          title: 'Mila Cares | Performance',
        },
      },
      {
        ...Filters,
        path: '/filters',
        exact: true,
        meta: {
          title: 'Mila Cares | HEPA + Carbon Filters',
        },
      },
      {
        ...FilterWrapper,
        path: '/filters/:type',
        exact: false,
        meta: {},
      },
      {
        ...AppUpdate,
        path: '/lp/update-113',
        exact: true,
        meta: {
          title: 'Mila Cares | App Updates | Update 1.13',
        },
      },
      {
        ...WhyMilaLanding,
        path: '/lp/why-mila',
        exact: true,
        meta: {},
      },
      {
        ...Peacekeeper,
        path: '/lp/peacekeeper',
        exact: true,
        meta: {
          title:
            'Air Filter to Keep the Air Relaxed and Drama Free | The Peacekeeper | Mila Cares',
        },
      },
      {
        ...Gottcha,
        path: '/lp/gotcha',
        exact: true,
        meta: {
          title: 'Gotcha! | Happy April Fool’s | Mila Cares',
        },
      },
      {
        ...Warranty,
        path: '/lp/warranty',
        exact: true,
        meta: {
          title: 'Warranty | Mila Cares',
        },
      },
      {
        ...ProductLanding,
        path: '/product/:type',
        exact: true,
        meta: {},
      },
      {
        ...FilterStandaloneWraper,
        path: '/lp/:type',
        exact: true,
        meta: {},
      },
      {
        ...WhyMila,
        path: '/why-mila',
        exact: true,
        meta: {
          title: 'Mila Cares | How Mila is Different',
        },
      },
      {
        ...OurStory,
        path: '/our-story',
        exact: true,
        meta: {
          title: 'Mila Cares | Our Story',
        },
      },
      {
        ...TheWallOfLove,
        path: '/the-wall-of-love',
        exact: true,
        meta: {
          title: 'Mila Cares | The Wall of Love',
        },
      },
      {
        ...StandWithUkraine,
        path: '/stand-with-ukraine',
        exact: true,
        meta: {
          title: 'Mila Cares | Stand With Ukraine',
        },
      },
      {
        ...Support,
        path: '/support',
        exact: true,
        meta: {
          title: 'Mila Cares | Support',
        },
      },
      {
        ...Careers,
        path: '/careers',
        exact: true,
        meta: {
          title: 'Mila Cares | Careers',
        },
      },
      {
        ...Referral,
        path: '/referral',
        exact: true,
        meta: {
          title: 'Refer a Friend | Give $50, Get a Free Filter',
        },
      },
      {
        ...Conciairge,
        path: '/conciairge',
        exact: true,
        meta: {
          title: 'Mila Cares | Conciairge',
        },
      },
      {
        ...AccessibilityPolicy,
        path: '/accessibility-policy',
        exact: true,
        meta: {
          title: 'Mila Cares | Accessibility Policy',
        },
      },
      {
        ...AppPage,
        path: '/app',
        exact: true,
        meta: {
          title: 'Mila Cares | App',
          trailingSlash: true,
        },
      },
      {
        ...CESLanding,
        path: '/sneakpeek',
        exact: true,
        meta: {
          title: 'Mila Cares | Product Sneak Peek',
        },
      },
      {
        ...NotFound,
      },
    ],
  },
];
