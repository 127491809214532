import React from 'react';

const warrantyFaqStatic = {
  header: {
    subtitle: 'Warranty',
    title: 'Frequently asked questions',
    shortDescription: <p>Have a Q? Find your A below.</p>,
  },
  faqItems: [
    {
      heading: 'General',
      content: [
        {
          title: 'What is your warranty?',
          details: (
            <>
              <p>
                Each Mila purchased directly from the Mila website or from an
                authorized seller (
                <a
                  href='https://www.amazon.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Amazon.com
                </a>
                {' '}
                in the US, Best Buy, The Home Depot) is officially covered by a
                12-month (one-year) standard warranty. We have now extended this
                to a 24-month (two-year) warranty for all Mila units.
              </p>
              <p>
                <strong>For Auto-Refill subscribers:</strong>
                {' '}
                If you are an
                Auto-Refill subscriber in good standing, you’ll automatically
                receive another year of warranty coverage, extending it to three
                years in total. Furthermore, should anything go awry after the
                three-year mark, you’re eligible for a $99 replacement unit for
                up to two additional years.
              </p>
              <p>
                Please note that disassembling your Mila unit in any way will
                void the warranty. If you encounter any issues or have questions
                about your warranty coverage, please don’t hesitate to reach out
                to us at
                {' '}
                <a href='mailto:support@milacares.com'>support@milacares.com</a>
                .
              </p>
            </>
          ),
        },
      ],
    },
    {
      heading: 'Auto-Refill Benefits',
      content: [
        {
          title:
            'What additional warranty benefits do Auto-Refill subscribers receive?',
          details: (
            <>
              <p>
                If you are on Auto-Refill you will automatically get another
                year of coverage (that’s 36 months total) as long as you remain
                a subscriber in good standing. Being in good standing means you
                have an active Auto-Refill subscription, and have ordered at
                least one filter in the last 12 months. You’ll also want to make
                sure that your payment methods are up to date.  Regular filter
                replacements are a key part of keeping your air – and your Mila
                – running in tip top shape.
              </p>
              <p>
                If you’re on Auto-Refill and experience any warranty-covered
                issues after the 36-month mark, and up to 60 months, you can get
                a replacement unit for:
              </p>
              <ul>
                <li>
                  <strong>United States:</strong>
                  {' '}
                  $99 USD
                </li>
                <li>
                  <strong>Canada:</strong>
                  {' '}
                  $125 CAD
                </li>
                <li>
                  <strong>Australia:</strong>
                  {' '}
                  $139 AUD
                </li>
              </ul>
            </>
          ),
        },
        {
          title:
            'How do I become an Auto-Refill subscriber to qualify for extended warranty coverage?',
          details: (
            <ul>
              <li>
                Whenever you purchase a Mila directly from us, you’ll always
                have an option to activate your Auto-Refill. In addition to
                immediate savings you’ll receive, this ensures you’ll get a
                fresh, new filter every six months, keeping your Mila running in
                tip top shape. You’ll now also enjoy an extended 12 months of
                warranty as long as you keep that Auto-Refill active, increasing
                your coverage to 36 months from the time of purchase.
              </li>
              <li>
                If you’re not sure what the status is of your current Mila, you
                can check on it at any time through
                {' '}
                <a
                  href='https://account.milacares.com/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  My Account
                </a>
                .
              </li>
            </ul>
          ),
        },
        {
          title:
            'What happens if I’m an Auto-Refill subscriber and I cancel my subscription?',
          details: (
            <ul>
              <li>
                If you cancel your Auto-Refill, you’ll no longer be eligible for
                extended coverage for the third year and the discounted
                replacement offer for the fourth and fifth years. The standard
                two-year warranty will still apply starting at the time of
                purchase.
              </li>
            </ul>
          ),
        },
      ],
    },
    {
      heading: 'Claiming Warranty',
      content: [
        {
          title: 'How do I claim a warranty for my Mila unit?',
          details: (
            <ul>
              <li>
                To claim a warranty for your Mila unit, visit this link to fill
                out the warranty claim form:
                {' '}
                <a
                  href='https://milamarketing.typeform.com/to/u1aEP1qs#email=xxxxx&ticketid=xxxxx&concern=xxxxx&status=xxxxx'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Mila Warranty Claim Form
                </a>
                . This form will take about 10 minutes to complete. You’ll be
                asked for:
                {' '}
                <ul>
                  <li>
                    Basic contact information such as your name, address and
                    email address
                  </li>
                  <li>Your MAC address</li>
                  <li>Details around what you’re experiencing</li>
                  <li>Your order details</li>
                  <li>
                    If needed, you’ll also have an opportunity to submit a video
                    that illustrates what you’re experiencing.
                  </li>
                </ul>
              </li>
            </ul>
          ),
        },
        {
          title: 'Can I claim a warranty without proof of purchase?',
          details: (
            <ul>
              <li>
                While we do require proof of purchase to process warranty
                claims, we understand that it may not always be available (e.g.,
                if your Mila was a gift). If you can't find your receipt or
                order confirmation, please contact us at
                {' '}
                <a href='mailto:mailto:support@milacares.com'>
                  support@milacares.com
                </a>
                . We will do our best to assist you and explore all possible
                solutions, but please be prepared to provide some basic details
                about the original purchase(r) such as name and/or email to help
                verify the order. Please note that this policy only applies to
                Mila units purchased directly from the Mila website or
                authorized sellers (Amazon where either Mila USA Inc. or Amazon
                is the seller, The Home Depot, Best Buy).
              </li>
            </ul>
          ),
        },
      ],
    },
    {
      heading: 'Extended Warranty Coverage ',
      content: [
        {
          title: 'What is involved with extended warranty?',
          details: (
            <ul>
              <li>
                For Auto-Refill subscribers who experience any warranty-covered
                issues beyond their 36 month coverage, you can get replacement
                unit at for $99 USD, which represents a significantly discounted
                rate for a new unit.
              </li>
            </ul>
          ),
        },
        {
          title: 'Is there any warranty on replacement units I pay for?',
          details: (
            <>
              <p>
                Any replacements that you pay for under this extended warranty
                program is covered by a 6-month warranty for defects in
                materials or workmanship.
              </p>
              <p>
                If the replacement is issued within the standard warranty
                period, the remaining duration of that warranty will continue to
                apply to the replacement unit. If you’re on Auto-Refill, this is
                36 months as long as you remain an Auto-Refill subscriber in
                good standing. Otherwise, the standard warranty period is 24
                months. In any case, if you ever have any questions about your
                Mila, even after the warranty period, please do not hesitate to
                contact us at
                {' '}
                <a href='mailto:support@milacares.com'>support@milacares.com</a>
                .
              </p>
            </>
          ),
        },
      ],
    },
    {
      heading: 'Miscellaneous',
      content: [
        {
          title: 'Can I transfer my warranty if I gift my Mila? ',
          details: (
            <ul>
              <li>
                Yes, if you gift your Mila, you can also transfer the warranty, provided that the original proof of purchase is available and the warranty period has not expired.
              </li>
            </ul>
          ),
        },
        {
          title: 'My Mila stopped working but I’m outside of the warranty period. What are my options?',
          details: (
            <p>
              If your Mila stops working after the warranty period—24 months for all customers, extended to 60 months for Auto-Refill subscribers—you can still contact our Care Squad at
              {' '}
              <a href='mailto:support@milacares.com'>support@milacares.com</a>
              {' '}
              for assistance.
            </p>
          ),
        },
      ],
    },
  ],
};

export default warrantyFaqStatic;
