export class CustomKlaviyo {
  PUBLIC_API_KEY;
  KLAVIYO_API_URL = 'https://a.klaviyo.com/client';
  env;

  constructor(public_api_key, env) {
    this.PUBLIC_API_KEY = public_api_key;
    this.env = env;
  }

  generateURL(path) {
    if (!path.startsWith('/')) path = '/' + path;
    return `${this.KLAVIYO_API_URL}${path}?company_id=${this.PUBLIC_API_KEY}`;
  }

  generateOptions(payload) {
    return {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'content-type': 'application/vnd.api+json',
        revision: '2024-10-15',
      },
      body: JSON.stringify(payload),
    };
  }

  async subscribeOnMilaNews(email, listId) {
    try {
      const url = this.generateURL('/subscriptions');
      const options = this.generateOptions({
        data: {
          type: 'subscription',
          attributes: {
            profile: {
              data: {
                type: 'profile',
                attributes: { email },
              },
            },
          },
          relationships: {
            list: {
              data: {
                type: 'list',
                id: listId,
              }
            }
          },
        },
      });
      const response = await fetch(url, options);

      if (response.status === 202) {
        return {
          success: response.ok,
          message: 'You have been successfully subscribed ✍️',
        };
      }

      const data = await response.json();

      const errorMessages = data.errors
        .map((error) => {
          return this.env === 'development'
            ? error.detail
            : 'Something went wrong';
        })
        .join(', ');

      return {
        success: false,
        message:
          this.env === 'development'
            ? errorMessages || 'Unknown error'
            : 'Something went wrong',
      };
    } catch (error) {
      return {success: false, message: error.message};
    }
  }
}
