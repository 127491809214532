import baby from 'assets/images/baby.png';
import sneezingFace from 'assets/images/sneezing-face.png';
import construction from 'assets/images/construction.png';
import dogFace from 'assets/images/dog-face.png';
import woman from 'assets/images/woman.png';
import leafFlutteringInWind from 'assets/images/leaf-fluttering-in-wind.png';
import explodingHead from 'assets/images/exploding-head.png';

const milaMoodListStatic = [
  {
    id: 'rookie-parent',
    image: {
      url: baby,
      alt: 'baby',
    },
    title: 'The Rookie Parent',
  },
  {
    id: 'big-sneeze',
    image: {
      url: sneezingFace,
      alt: 'sneezing face',
    },
    title: 'The Big Sneeze',
  },
  {
    id: 'home-wrecker',
    image: {
      url: construction,
      alt: 'construction',
    },
    title: 'The Home Wrecker',
  },
  {
    id: 'critter-cuddler',
    image: {
      url: dogFace,
      alt: 'Dog face',
    },
    title: 'The Critter Cuddler',
  },
  {
    id: 'mama-to-be',
    image: {
      url: woman,
      alt: 'Woman',
    },
    title: 'The Mama-to-Be',
  },
  {
    id: 'basic-breather',
    image: {
      url: leafFlutteringInWind,
      alt: 'Leaf fluttering in wind',
    },
    title: 'The Basic Breather',
  },
  {
    id: 'overreactor',
    image: {
      url: explodingHead,
      alt: 'Exploding head',
    },
    title: 'The Overreactor',
  },
];

export default milaMoodListStatic;
