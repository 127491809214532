/**
 * @param value
 * @param region
 * @param measure
 * @return {string|string}
 */
const getPerfectSquare = (value, region = 'US', measure = 'ft') => {
  const square = measure === 'ft' ? parseInt(value, 10) : parseInt(value, 10) * 0.09290304;
  return Number.isNaN(square) ? 'N/A' : new Intl.NumberFormat(`en-${region}`).format(square.toFixed(1));
};

export default getPerfectSquare;
