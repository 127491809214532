import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import {
  fetchRegionalPrices,
  selectRegionalPricesStatus,
} from '../features/regionalPrices/regionalPricesSlice';
import { STATUS } from '../../shared/enums';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';

const OrderBlock = loadable(() => import('../components/common/OrderBlock'));
const MediaBlock = loadable(() => import('../components/why-mila/MediaBlock'));
const HeroFancyHeading = loadable(() => import('../components/why-mila/HeroFancyHeading'));

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */

const WhyMila = props => {
  const { route } = props;

  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const status = useSelector(selectRegionalPricesStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  useEffect(() => {
    if (![ PREFETCHED, SUCCEEDED ].includes(status)) {
      dispatch(fetchRegionalPrices(localization));
    }
    return undefined;
  }, []);

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <HeroFancyHeading fallback={<FallbackPlaceholder />} />
      <MediaBlock />
      {[ PREFETCHED, SUCCEEDED ].includes(status) && (<OrderBlock />)}
    </>
  );
};

const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchRegionalPrices(data),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default { component: WhyMila, loadData };
