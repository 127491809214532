import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';
import {CustomKlaviyo} from "./CustomKlaviyo";

export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const klaviyoSubscribe = createAsyncThunk('klaviyo/klaviyoSubscribe', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { email } = payload;
    const customKlaviyo = new CustomKlaviyo(KLAVIYO_PUBLIC_API_KEY, NODE_ENV);
    const {
      success, message
    } = await customKlaviyo.subscribeOnMilaNews(email, KLAVIYO_WEBSITE_SUBSCRIBERS_LIST_ID);
    return {
      title: success ? message : 'Something went wrong 😞'
    }
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const klaviyoSlice = createSlice({
  name: 'klaviyo',
  initialState,
  reducers: {
    clearKlaviyo: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(klaviyoSubscribe.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(klaviyoSubscribe.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
        state.error = initialState.error;
      })
      .addCase(klaviyoSubscribe.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearKlaviyo } = klaviyoSlice.actions;

export const selectKlaviyoData = state => state.klaviyo.data;
export const selectKlaviyoStatus = state => state.klaviyo.status;
export const selectKlaviyoError = state => state.klaviyo.error;

export default klaviyoSlice.reducer;
