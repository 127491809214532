import React from 'react';

const appWidgetsNightStatic = {
  heading: '...Magic by night.',
  widgets: [
    {
      icon: (
        <svg
          width='29'
          height='25'
          viewBox='0 0 29 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.1346 4.65042C2.26795 3.80004 2.90331 3.13322 3.7449 2.95251C5.85242 2.49998 10.1032 1.72095 14.3913 1.72095C18.633 1.72095 22.8437 2.48323 24.9773 2.93773C25.8562 3.12495 26.5082 3.83825 26.6303 4.72851C26.8846 6.58216 27.2734 9.8797 27.2734 12.7111C27.2734 15.5628 26.879 18.8873 26.6249 20.7333C26.5051 21.6031 25.8786 22.3057 25.023 22.5027C22.959 22.978 18.8483 23.7708 14.3913 23.7708C9.88462 23.7708 5.73756 22.9602 3.70017 22.4869C2.88174 22.2968 2.27139 21.6394 2.14052 20.8094C1.88308 19.1769 1.5091 16.2042 1.5091 12.7111C1.5091 9.24473 1.87739 6.29077 2.1346 4.65042Z'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M7.54501 7.86865C7.54501 7.86865 6.84375 9.99643 6.84375 11.9153C6.84375 13.8343 7.54501 16.4531 7.54501 16.4531'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
        </svg>
      ),
      title: (
        <>
          Turndown
          <br />
          Service
        </>
      ),
      description: (
        <p>
          An hour before bedtime, Mila will give your room a deep clean to
          reduce nighttime allergies (and kinda make you feel like royalty).
        </p>
      ),
    },
    {
      icon: (
        <svg
          width='33'
          height='26'
          viewBox='0 0 33 26'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.26953 1.47473H27.4868C28.902 1.47473 30.0493 2.622 30.0493 4.03723V13.508H2.70703V4.03723C2.70703 2.622 3.8543 1.47473 5.26953 1.47473Z'
            stroke='black'
            strokeWidth='2'
          />
          <path
            d='M8.80847 7.85352H23.9447C24.497 7.85352 24.9447 8.30123 24.9447 8.85352V12.508C24.9447 13.0603 24.497 13.508 23.9447 13.508H8.80847C8.25619 13.508 7.80847 13.0603 7.80847 12.508V8.85352C7.80847 8.30123 8.25619 7.85352 8.80847 7.85352Z'
            stroke='black'
            strokeWidth='2'
          />
          <path
            d='M3.42969 12.9565H29.3234C30.428 12.9565 31.3234 13.852 31.3234 14.9565V20.1625C31.3234 20.7148 30.8757 21.1625 30.3234 21.1625H2.42969C1.8774 21.1625 1.42969 20.7148 1.42969 20.1625V14.9565C1.42969 13.852 2.32512 12.9565 3.42969 12.9565Z'
            stroke='black'
            strokeWidth='2'
          />
          <line
            x1='4.53125'
            y1='24.9897'
            x2='4.53125'
            y2='21.8867'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='bevel'
          />
          <line
            x1='28.7734'
            y1='24.9897'
            x2='28.7734'
            y2='21.8867'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='bevel'
          />
        </svg>
      ),
      title: (
        <>
          Sleep
          <br />
          Mode
        </>
      ),
      description: (
        <p>
          Whether you’re a light sleeper or practically a corpse, you can set
          Mila to respect your sleep. It’ll keep the lights off and fans low
          until your wake-up time.
        </p>
      ),
    },
    {
      icon: (
        <svg
          width='33'
          height='22'
          viewBox='0 0 33 22'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M3.75464 19.7201C5.36835 18.5824 8.28035 16.626 11.7029 19.4002C15.1255 22.1744 18.5174 20.3281 19.7298 19.7201C21.4285 18.473 25.6007 16.727 28.6996 19.7201'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M25.929 17.0478C25.929 11.8177 21.6891 7.57776 16.4589 7.57776C11.2287 7.57776 6.98883 11.8177 6.98883 17.0478'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M16.5121 3.75047V1.19897'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M3.11675 13.9564L1.20312 13.9564'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M31.8199 13.9564L29.9062 13.9564'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M26.7175 5.0262L25.4417 6.30197'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M6.3061 5.02625L7.58185 6.302'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
        </svg>
      ),
      title: (
        <>
          Whitenoise
          <br />
          Mode
        </>
      ),
      description: (
        <p>
          Find the sound of a rhythmic, oscillating fan relaxing? Mila’s got a
          mode for that. Take your pick from settings like Ocean Dreaming to
          Ujjay Breathing.
        </p>
      ),
    },
  ],
};
export default appWidgetsNightStatic;
