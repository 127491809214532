import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { setFallback } from '../../features/fallback/fallbackSlice';

const FallbackPlaceholder = () => {
  const dispatch = useDispatch();
  const dimensions = useWindowDimensions();
  useEffect(() => {
    dispatch(setFallback(true));

    return () => {
      dispatch(setFallback(false));
    };
  }, []);
  return (<div className='fallback-loading' style={{ height: `${dimensions.height}px` }} />);
};

export default FallbackPlaceholder;
