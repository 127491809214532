/**
 * Convert to boolean type and return test result
 *
 * @param value
 * @returns {*}
 */
const getTrue = (value: any): boolean => {
  const processed = typeof value === 'string' ? value.toLowerCase().trim() : value;
  return [ true, 'true', 1 ].some(t => t === processed);
};

export default getTrue;
