import React, { useEffect, useState, useRef } from 'react';
import { renderRoutes } from 'react-router-config';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import 'assets/css/style.scss';

import {
  fetchBanners,
  selectBannersStatus, setBannersIdle,
} from './features/banners/bannersSlice';
import { selectFallbackLoading } from './features/fallback/fallbackSlice';

import { STATUS, PAGES_WITHOUT_NAV } from '../shared/enums';

const HelmetWrapper = loadable(() => import('./components/root/HelmetWrapper'));
const ErrorBoundary = loadable(() => import('./components/root/ErrorBoundary'));
const Footer = loadable(() => import('./components/root/Footer'));
const Header = loadable(() => import('./components/root/Header'));
const Icons = loadable(() => import('./components/root/Icons'));
const ScrollToTop = loadable(() => import('./components/root/ScrollToTop'));
const TopBar = loadable(() => import('./components/root/TopBar'));

/**
 * @param route
 * @param pathname
 * @returns {JSX.Element}
 * @constructor
 */

const App = ({ route, location: { pathname }}) => {
  const dispatch = useDispatch();
  const status = useSelector(selectBannersStatus);
  const { loading } = useSelector(selectFallbackLoading);
  const [ isNavActive, setIsNavActive ] = useState(false);

  const [ scrollPosition, setScrollPosition ] = useState(false);

  const topBarRef = useRef(null);
  const headerRef = useRef(null);
  const mainRef = useRef(null);

  const { PREFETCHED } = STATUS;

  const isMenuActive = isNavActive ? 'nav__visible' : '';
  const isScrollPosition = scrollPosition ? 'scroll-up' : 'scroll-down';

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    status !== PREFETCHED
      ? dispatch(fetchBanners())
      : dispatch(setBannersIdle());

    return undefined;
  }, [ pathname ]);

  useEffect(() => {
    let lastScrollTop = 0;

    function scrollDirection() {
      const pageOffsetY = window.pageYOffset || document.documentElement.scrollTop;
      if (pageOffsetY > lastScrollTop) {
        setScrollPosition(true);
      } else if (pageOffsetY < lastScrollTop) {
        setScrollPosition(false);
      }
      lastScrollTop = pageOffsetY <= 0 ? 0 : pageOffsetY;
    }
    window.addEventListener('scroll', scrollDirection);

    return () => {
      window.removeEventListener('scroll', scrollDirection);
    };
  }, []);

  return (
    <div className={`${loading ? 'components-loading ' : ''} ${isMenuActive} ${isScrollPosition}`}>
      <ScrollToTop />
      <HelmetWrapper pathname={pathname} />
      <ErrorBoundary>
        {!PAGES_WITHOUT_NAV.includes(pathname)
          && <TopBar topBarRef={topBarRef} pathname={pathname} />}
      </ErrorBoundary>
      <ErrorBoundary>
        <Header
          pathname={pathname}
          isNavActive={isNavActive}
          setIsNavActive={setIsNavActive}
          mainRef={mainRef}
          topBarRef={topBarRef}
          headerRef={headerRef}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <main className='main' ref={mainRef}>
          {renderRoutes(route.routes, { topBarRef, headerRef })}
        </main>
      </ErrorBoundary>
      <ErrorBoundary>
        {!PAGES_WITHOUT_NAV.includes(pathname)
          && <Footer pathname={pathname} />}
      </ErrorBoundary>
      <Icons />
    </div>
  );
};

const loadData = store => {
  const actions = [
    fetchBanners(),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default {
  component: App,
  loadData,
};
