import React from 'react';

const appWidgetsDayStatic = {
  heading: 'Magic by day...',
  widgets: [
    {
      icon: (
        <svg
          width='30'
          height='34'
          viewBox='0 0 30 34'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M27.0121 30.9681L24.7886 24.2976L2.7004 24.2977V30.1293C2.7004 31.3874 3.72029 32.4073 4.97839 32.4073H25.9748C26.7211 32.4073 27.2481 31.6762 27.0121 30.9681Z'
            stroke='white'
            strokeWidth='2.55134'
          />
          <path
            d='M20.0117 28.6582L21.7859 31.8021'
            stroke='white'
            strokeWidth='2.55134'
            strokeMiterlimit='10'
            strokeLinecap='round'
          />
          <path
            d='M14.4961 28.6582L16.2702 31.8021'
            stroke='white'
            strokeWidth='2.55134'
            strokeMiterlimit='10'
            strokeLinecap='round'
          />
          <path
            d='M8.76172 28.6582L10.5359 31.8021'
            stroke='white'
            strokeWidth='2.55134'
            strokeMiterlimit='10'
            strokeLinecap='round'
          />
          <path
            d='M1.51533 21.6244V24.1153H26.2087V21.6127C26.2087 20.8186 25.7951 20.0818 25.1172 19.6682L17.1735 14.8211L10.6593 14.8211L2.61647 19.6739C1.93307 20.0863 1.51533 20.8262 1.51533 21.6244Z'
            stroke='white'
            strokeWidth='2.55134'
          />
          <path
            d='M16.736 14.6389V4.66131C16.736 3.07611 15.451 1.79105 13.8658 1.79105C12.2806 1.79105 10.9955 3.07611 10.9955 4.66132V14.6389H16.736Z'
            stroke='white'
            strokeWidth='2.55134'
          />
        </svg>
      ),
      title: (
        <>
          Housekeeping
          {' '}
          <br />
          Service
        </>
      ),
      description: (
        <p>
          Let Mila deep-clean your room when no one’s around, so you can come
          home to a hotel-fresh space.
        </p>
      ),
    },
    {
      icon: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M23.3004 10.426C25.3656 10.8391 29.4961 12.6069 29.4961 16.3739C29.7439 18.4391 28.8517 22.9165 23.3004 24.3043'
            stroke='white'
            strokeWidth='3'
          />
          <mask id='path-2-inside-1_11988_31413' fill='white'>
            <path d='M0.996094 11.4348C0.996094 10.3302 1.89152 9.43481 2.99609 9.43481H11.4048V23.3131H2.99609C1.89153 23.3131 0.996094 22.4176 0.996094 21.3131V11.4348Z' />
          </mask>
          <path
            d='M0.996094 11.4348C0.996094 10.3302 1.89152 9.43481 2.99609 9.43481H11.4048V23.3131H2.99609C1.89153 23.3131 0.996094 22.4176 0.996094 21.3131V11.4348Z'
            stroke='white'
            strokeWidth='6'
            strokeLinejoin='round'
            mask='url(#path-2-inside-1_11988_31413)'
          />
          <path
            d='M10.1656 11.1696L22.8048 2V30.5L10.1656 21.5783'
            stroke='white'
            strokeWidth='3'
            strokeLinejoin='round'
          />
        </svg>
      ),
      title: (
        <>
          Quiet
          {' '}
          <br />
          Mode
        </>
      ),
      description: (
        <p>
          Have Mila reduce fan speeds to whisper-quiet levels when you’re in the
          room, so she’s never a bother.
        </p>
      ),
    },
    {
      icon: (
        <svg
          width='29'
          height='29'
          viewBox='0 0 29 29'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            cx='14.4347'
            cy='14.6506'
            r='12.7567'
            stroke='white'
            strokeWidth='2.55134'
          />
        </svg>
      ),
      title: (
        <>
          Bubble Boy
          <br />
          Mode
        </>
      ),
      description: (
        <p>
          This one’s for all you bubble boys and girls. Give Mila permission to
          do whatever it takes to make your room particle free, regardless of
          noise level.
        </p>
      ),
    },
  ],
};
export default appWidgetsDayStatic;
