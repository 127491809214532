import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';

export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const fetchExchangeRates = createAsyncThunk('exchangeRates/fetchExchangeRates', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/exchange', { params });
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearExchangeRates: reducers.clearExchangeRates}, string>}
 */
export const exchangeRatesSlice = createSlice({
  name: 'exchangeRates',
  initialState,
  reducers: {
    clearExchangeRates: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchExchangeRates.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
        state.error = initialState.error;
      })
      .addCase(fetchExchangeRates.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearExchangeRates } = exchangeRatesSlice.actions;

export const selectExchangeRatesData = state => state.exchangeRates.data;
export const selectExchangeRatesStatus = state => state.exchangeRates.status;
export const selectExchangeRatesError = state => state.exchangeRates.error;

export default exchangeRatesSlice.reducer;
