import React from 'react';
import { Helmet } from 'react-helmet-async';
import pug from 'assets/images/pug.gif';
import { Link } from 'react-router-dom';

const NotFound = ({ staticContext = {}}) => {
  staticContext.notFound = true;

  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <section className='error404'>
        <div className='container'>
          <div className='error404__content'>
            <h1 className='error404__title'>
              P’ugh!
              <br />
              {' '}
              What just happened?
            </h1>
            <p>
              Looks like something’s busted. We’ll get our geeks on it asap. In the meantime, let’s get you over to
              the homepage where everything actually works (we hope).
            </p>
            <figure className='error404__image'>
              <Link to='/'>
                <img src={pug} alt='Pug' />
                <span className='screen-reader-text'>return to home</span>
              </Link>
            </figure>
          </div>
        </div>
      </section>
    </>
  );
};

NotFound.defaultProps = {
  staticContext: {},
};

export default {
  component: NotFound,
};
