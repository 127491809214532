import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { warrantyFaqStatic } from '../static-data';

const SupportFaq = loadable(() => import('../components/support/SupportFaq'));
/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const Warranty = props => {
  const { route } = props;

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <div className='page-header'>
        <div className='container'>
          <h1 className='page-header__heading'>Warranty</h1>
        </div>
      </div>
      <SupportFaq {...warrantyFaqStatic} />
      <div className='support-card'>
        <div className='container'>
          <div className='support-card__card'>
            <strong className='support-card__leadtext'>Contact us</strong>
            <h2 className='support-card__title'>Need an extra hand?</h2>
            <div className='support-card__description'>
              <p>
                We’re here to make sure you have a great experience with Mila.
                Reach out any time, day or night.
              </p>
            </div>
            <p className='support-card__email'>
              Email
              <br />
              <a href='mailto:support@milacares.com'>support@milacares.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
Warranty.defaultProps = {
  staticContext: {},
};

export default { component: Warranty };
