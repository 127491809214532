import {
  configureStore,
  combineReducers,
} from '@reduxjs/toolkit';
import bannersReducer from '../client/features/banners/bannersSlice';
import reviewsReducer from '../client/features/reviews/reviewsSlice';
import exchangeRatesReducer from '../client/features/exchangeRates/exchangeRatesSlice';
import mailchimpReducer from '../client/features/mailchimp/mailchimpSlice';
import klaviyoReducer from '../client/features/klaviyo/klaviyoSlice';
import regionalPricesReducer from '../client/features/regionalPrices/regionalPricesSlice';
import localizationReducer from '../client/features/localization/localizationSlice';
import fallbackReducer from '../client/features/fallback/fallbackSlice';
import rootReducer, { resetApp } from '../client/features/root/rootSlice';

const combinedReducer = combineReducers({
  root: rootReducer,
  banners: bannersReducer,
  reviews: reviewsReducer,
  exchangeRates: exchangeRatesReducer,
  mailchimp: mailchimpReducer,
  klaviyo: klaviyoReducer,
  regionalPrices: regionalPricesReducer,
  localization: localizationReducer,
  fallback: fallbackReducer,
});

const getReducer = (state, action) => {
  if (action.type === resetApp.toString()) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default (preloadedState = {}) => configureStore({
  reducer: getReducer,
  preloadedState,
});
