import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';

export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const mailchimpSubscribe = createAsyncThunk('mailchimp/mailchimpSubscribe', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/mailchimp', payload);
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearMailchimp: reducers.clearMailchimp}, string>}
 */
export const mailchimpSlice = createSlice({
  name: 'mailchimp',
  initialState,
  reducers: {
    clearMailchimp: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(mailchimpSubscribe.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(mailchimpSubscribe.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
        state.error = initialState.error;
      })
      .addCase(mailchimpSubscribe.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearMailchimp } = mailchimpSlice.actions;

export const selectMailchimpData = state => state.mailchimp.data;
export const selectMailchimpStatus = state => state.mailchimp.status;
export const selectMailchimpError = state => state.mailchimp.error;

export default mailchimpSlice.reducer;
