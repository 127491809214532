import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';

export const initialState = {
  data: null,
  dataStatus: STATUS.IDLE,
  dataError: null,
  preview: null,
  previewStatus: STATUS.IDLE,
  previewError: null,
};

export const fetchReviews = createAsyncThunk('reviews/fetchReviews', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/reviews');
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const fetchReviewsPreview = createAsyncThunk('reviews/fetchReviewsPreview', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/reviews-preview');
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearReviews: reducers.clearReviews}, string>}
 */
export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    clearReviews: state => {
      state.data = initialState.data;
      state.preview = initialState.preview;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchReviews.pending, state => {
        state.dataStatus = STATUS.LOADING;
      })
      .addCase(fetchReviews.fulfilled, (state, action) => {
        state.dataStatus = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
        state.dataError = initialState.error;
      })
      .addCase(fetchReviews.rejected, (state, action) => {
        state.dataStatus = STATUS.FAILED;
        state.dataError = action.payload;
      })
      .addCase(fetchReviewsPreview.pending, state => {
        state.previewStatus = STATUS.LOADING;
      })
      .addCase(fetchReviewsPreview.fulfilled, (state, action) => {
        state.previewStatus = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.preview = action.payload;
        state.previewError = initialState.error;
      })
      .addCase(fetchReviewsPreview.rejected, (state, action) => {
        state.previewStatus = STATUS.FAILED;
        state.previewError = action.payload;
      });
  },
});

export const { clearReviews } = reviewsSlice.actions;

export const selectReviewsData = state => state.reviews.data;
export const selectReviewsStatus = state => state.reviews.dataStatus;
export const selectReviewsError = state => state.reviews.dataError;

export const selectReviewsPreview = state => state.reviews.preview;
export const selectReviewsPreviewStatus = state => state.reviews.previewStatus;
export const selectReviewsPreviewError = state => state.reviews.previewError;

export default reviewsSlice.reducer;
