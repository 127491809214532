/**
 * @type {{BB: {backgroundColor: string, borderColor: string, color: string, text: string, title: string}, CC: {backgroundColor: string, borderColor: string, color: string, text: string, title: string}, BS: {backgroundColor: string, borderColor: string, color: string, text: string, title: string}, OR: {backgroundColor: string, borderColor: string, color: string, text: string, title: string}, MB: {backgroundColor: string, borderColor: string, color: string, text: string, title: string}, RP: {backgroundColor: string, borderColor: string, color: string, text: string, title: string}, HW: {backgroundColor: string, borderColor: string, color: string, text: string, title: string},PC: {backgroundColor: string, borderColor: string, color: string, text: string, title: string}}}
 */
const filterOrderStatic = {
  BB: {
    title: 'Those pesky particulates are everywhere.',
    text: 'Though you may not have to worry about VOCs, pets, or household odors in your home, '
      + 'you’re bound to encounter airborne particulate matter (PM). The Basic Breather protects you from all the villains:',
    backgroundColor: '#3E4649',
    borderColor: '#3E4649',
    color: '#3E4649',
  },
  BS: {
    title: 'Home sweet (allergen-filled) home.',
    text: 'If you suffer from allergies, you’re probably familiar with all the particles that '
      + 'trigger your symptoms. The Big Sneeze filter tackles them all:',
    backgroundColor: '#FFCA0C',
    borderColor: '#FFCA0C',
    color: '#FFCA0C',
  },
  CC: {
    title: 'Pets aren’t exactly a breath of fresh air.',
    text: 'They’re an adorable part of the family, but our animals can do '
      + 'a number on our air quality. The Critter Cuddler filter covers the main concerns:',
    backgroundColor: '#F42D53',
    borderColor: '#F42D53',
    color: '#F42D53',
  },
  HW: {
    title: 'Your shiny new home is hiding something.',
    text: 'Though the scent of “new home” may smell like success, '
      + 'it’s filled with nasties you don’t want to be breathing. '
      + 'The Home Wrecker filter protects you from them all:',
    backgroundColor: '#FC830F',
    borderColor: '#FC830F',
    color: '#FC830F',
  },
  MB: {
    title: 'What mama breathes, so does baby.',
    text: 'Pregnant women and the babies they’re baking are particularly vulnerable to airborne nasties. '
      + 'The Mama-to-Be filter offers the ultimate protection from them all:',
    backgroundColor: '#1CE4D5',
    borderColor: '#1CE4D5',
    color: '#1CE4D5',
  },
  OR: {
    title: 'Calling all Type A breathers.',
    text: 'Better to breathe safe than sorry, right? '
      + 'The Overreactor is here to take care of just about everything '
      + 'that could possibly get in the way of you and clean air:',
    backgroundColor: '#0A56EA',
    borderColor: '#0A56EA',
    color: '#0A56EA',
  },
  RP: {
    title: 'Little lungs need a little extra care.',
    text: 'Raising a child takes a village—and a good air purifier. '
      + 'Your home air can contain everything from particulates to '
      + 'chemicals that you wouldn’t want your kiddos breathing:',
    backgroundColor: '#8551F0',
    borderColor: '#8551F0',
    color: '#8551F0',
  },
  PC: {
    title: 'Keep the air relaxed and drama free.',
    text: 'Bad juju builds up fast. One day everyone’s getting along just fine, the next day your partner threatens to leave over the way you loaded the dishwasher. The Peacekeeper will protect your home from the accumulation of sneaky bad vibes like these:',
    backgroundColor: '#FFC554',
    borderColor: '#FFC554',
    color: '#FFC554',
  },
};

export default filterOrderStatic;
