import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import Masonry from 'react-masonry-component';
import {
  fetchReviews,
  selectReviewsData,
  selectReviewsError,
  selectReviewsStatus,
} from '../features/reviews/reviewsSlice';
import { STATUS } from '../../shared/enums';

import FallbackPlaceholder from '../components/common/FallbackPlaceholder';
import generateKey from '../../shared/utils/generateKey';

const HeroLove = loadable(() => import('../components/the-wall-of-love/HeroLove'));
const ReviewsFilter = loadable(() => import('../components/the-wall-of-love/ReviewsFilter'));
const ReviewsMobileFilter = loadable(() => import('../components/the-wall-of-love/ReviewsMobileFilter'));
const ReviewCard = loadable(() => import('../components/the-wall-of-love/ReviewCard'));
const ClientQuote = loadable(() => import('../components/the-wall-of-love/ClientQuote'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const ReviewBlockWrapHolder = props => (
  <div className='review-block__wrap-holder'>
    <Masonry columns={2} className='review-block__wrap'>
      {React.Children.toArray(props.children)}
    </Masonry>
  </div>
);

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TheWallOfLove = props => {
  const {
    route, staticContext, topBarRef, headerRef,
  } = props;

  const dispatch = useDispatch();
  const reviews = useSelector(selectReviewsData);
  const status = useSelector(selectReviewsStatus);
  const error = useSelector(selectReviewsError);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  useEffect(() => {
    if (![ PREFETCHED, SUCCEEDED ].includes(status)) {
      dispatch(fetchReviews());
    }
    return undefined;
  }, []);

  staticContext.bodyClass = 'wall-of-love-refactored';

  const defaultSelectHTML = '<svg className=\'icon icon-filter\'><use xlinkHref=\'#icon-filter\'/></svg> Filter by';

  const [ selectedItemHTML, setSelectedItemHTML ] = useState(defaultSelectHTML);

  const [ selectedItem, setSelectedItem ] = useState([]);

  const components = reviews?.map(review => {
    const { type, author, text } = review;

    let result = selectedItem.length ? (selectedItem.includes(type)
      ? <ReviewCard author={author} type={type} text={text} isVisible='active' /> : '')
      : <ReviewCard author={author} type={type} text={text} isVisible='active' />;

    if (type.search('-quote') !== -1) {
      result = selectedItem.length
        ? (selectedItem.includes(type.replace('-quote', '')) ? <ClientQuote author={author} type={type.replace('-quote', '')} text={text} isVisible='active' /> : '')
        : <ClientQuote author={author} type={type.replace('-quote', '')} text={text} isVisible='active' />;
    }

    return result;
  });

  const isReviewLoaded = status !== STATUS.LOADING && !error;

  const [ isComponentLoaded, setIsComponentLoaded ] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(components !== undefined);
  }, [ components !== undefined ]);

  const reviewBlockRef = useRef(null);

  const getOffset = el => {
    if (!el.current.getClientRects().length) {
      return { top: 0, left: 0 };
    }

    const rect = el.current.getBoundingClientRect();
    const win = el.current.ownerDocument.defaultView;

    return (
      {
        top: rect.top + win.pageYOffset - 100,
        left: rect.left + win.pageXOffset,
      });
  };

  const scrollToSection = el => {
    const offset = getOffset(el).top - 24;
    window.scrollTo({ behavior: 'smooth', top: offset });
  };

  const scrollToReviewSection = () => {
    scrollToSection(reviewBlockRef);
  };

  const filterScrollTop = () => {
    scrollToSection(reviewBlockRef);
  };

  useEffect(() => {
    if (!isComponentLoaded) return;

    setTimeout(() => {
      scrollToSection(reviewBlockRef);
    }, 1000);
  }, [ isComponentLoaded ]);

  const [ offsetHeader, setHeaderOffset ] = useState(0);
  const [ stickyOffsetHeight, setStickyOffsetHeight ] = useState(0);

  useEffect(() => {
    const stickyFilter = () => {
      setHeaderOffset(topBarRef.current.offsetHeight + headerRef.current.offsetHeight);
      setStickyOffsetHeight(reviewBlockRef?.current.offsetHeight);
    };

    document.addEventListener('DOMContentLoaded', stickyFilter);
    window.addEventListener('resize', stickyFilter);
    window.addEventListener('scroll', stickyFilter);

    return () => {
      document.removeEventListener('DOMContentLoaded', stickyFilter);
      window.removeEventListener('resize', stickyFilter);
      window.removeEventListener('scroll', stickyFilter);
    };
  }, [ selectedItem ]);
  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <HeroLove fallback={<FallbackPlaceholder />} scrollToSection={scrollToReviewSection} isReviewLoaded={isReviewLoaded} />
      {!isReviewLoaded && <div className='image-placeholder' style={{ aspectRatio: `${16 / 9}`, marginTop: 40 }} />}
      {isReviewLoaded && (
        <section key={generateKey('review-block')} id='review-block' className='review-block' ref={reviewBlockRef}>
          <ReviewsMobileFilter
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            selectedItemHTML={selectedItemHTML}
            setSelectedItemHTML={setSelectedItemHTML}
            filterScrollTop={filterScrollTop}
            topOffset={offsetHeader + 5}
            reviewBlockRef={reviewBlockRef}
          />
          <ReviewsFilter
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            filterScrollTop={filterScrollTop}
            topOffset={offsetHeader + 40}
            bottomOfffset={stickyOffsetHeight}
          />
          <ReviewBlockWrapHolder
            reviewsLoaded={isComponentLoaded}
          >
            {components}
          </ReviewBlockWrapHolder>
        </section>
      )}
    </>
  );
};

const loadData = store => {
  const actions = [
    fetchReviews(),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

TheWallOfLove.defaultProps = {
  staticContext: {},
};

export default { component: TheWallOfLove, loadData };
