import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import {
  fetchRegionalPrices,
  selectRegionalPricesStatus,
} from '../features/regionalPrices/regionalPricesSlice';
import { STATUS } from '../../shared/enums';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';

const HeroFilter = loadable(() => import(/* webpackPrefetch: true */ '../components/filters/HeroFilter'));
const FilterMain = loadable(() => import('../components/filters/FilterMain'));
const FilterStats = loadable(() => import('../components/filters/FilterStats'));
const PerformanceStats = loadable(() => import('../components/filters/PerformanceStats'));
const ReplacementBlock = loadable(() => import('../components/filters/ReplacementBlock'));
const SockBlock = loadable(() => import('../components/filters/SockBlock'));
const FaqBlock = loadable(() => import('../components/filters/FaqBlock'));

/**
 * @returns {JSX.Element}
 * @constructor
 */

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */

const Filters = props => {
  const {
    staticContext, route, topBarRef, headerRef,
  } = props;
  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const status = useSelector(selectRegionalPricesStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  staticContext.bodyClass = 'filter-page-refactored';

  useEffect(() => {
    if (![ PREFETCHED, SUCCEEDED ].includes(status)) {
      dispatch(fetchRegionalPrices(localization));
    }
    return undefined;
  }, []);

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <HeroFilter fallback={<FallbackPlaceholder />} />
      {[ PREFETCHED, SUCCEEDED ].includes(status)
        && (<FilterMain topBarRef={topBarRef} headerRef={headerRef} />)}
      <FilterStats />
      <PerformanceStats />
      <ReplacementBlock />
      <SockBlock />
      <FaqBlock />
    </>
  );
};

const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchRegionalPrices(data),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

Filters.defaultProps = {
  staticContext: {},
};

export default { component: Filters, loadData };
