import hepa from 'assets/images/hepa.png';
import hepaWebp from 'assets/images/hepa.png?as=webp';

import sock from 'assets/images/sock.png';
import sockWebp from 'assets/images/sock.png?as=webp';

import wcfV1 from 'assets/images/wcf-v1.png';
import wcfV1Webp from 'assets/images/wcf-v1.png?as=webp';

import wcfV2 from 'assets/images/wcf-v2.png';
import wcfV2Webp from 'assets/images/wcf-v2.png?as=webp';

import pcCarbon from 'assets/images/pc-carbon.png';
import pcCarbonWebp from 'assets/images/pc-carbon.png?as=webp';

import hwCarbon from 'assets/images/hw-carbon.png';
import hwCarbonWebp from 'assets/images/hw-carbon.png?as=webp';
/**
 * @type {{BB: {intro: {text: string, title: string}, sliderItems: [{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt: string}, {text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string}]}, CC: {intro: null, sliderItems: [{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{inner: boolean}]}, BS: {intro: {text: string, title: string}, sliderItems: [{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string}]}, OR: {intro: {text: string, title: string}, sliderItems: [{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{inner: boolean}]}, MB: {intro: {text: string, title: string}, sliderItems: [{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{inner: boolean}]}, RP: {intro: {text: string, title: string}, sliderItems: [{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{inner: boolean}]}, HW: {intro: {text: string, title: string}, sliderItems: [{inner: boolean},{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string},{text: string, title: string, imgSrc: string, imgWebpSrc: string, imgAlt:string}]}}}
 */

const filterDetailStatic = {
  BB: {
    intro: {
      title: 'The filter for the ultimate everyday clean.',
      text:
        'Made with high-grade HEPA for maximum particle filtration, '
        + 'The Basic Breather takes care of the essentials. Highly efficient, '
        + 'it’s ideal for larger rooms.',
    },
    sliderItems: [
      {
        title: '447 CADR',
        text: 'This is how efficiently the filter cleans the air. Any number over 200 is great, and the Basic Breather crushes it.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: '447 CADR img',
      },
      {
        title: 'H12 HEPA layer',
        text: 'Filtering superfine particles, including dust and indoor/outdoor pollution.\n 99.6% efficiency.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: 'H12 HEPA layer ',
      },
    ],
  },
  BS: {
    intro: {
      title: 'Achoo no more.',
      text:
        'Designed for allergy sufferers, The Big Sneeze filter saves you '
        + 'from the misery with excellent particle filtration and an extra layer of defense.',
    },
    sliderItems: [
      {
        title: '416 CADR',
        text: 'This is how efficiently the filter cleans the air. Any number over 200 is 👌.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: '416 CADR img',
      },
      {
        title: 'H13 HEPA layer',
        text: 'Filtering superfine particles, including dust and indoor/outdoor pollution. 99.97% efficiency.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: 'H13 HEPA layer ',
      },
    ],
  },
  CC: {
    intro: null,
    sliderItems: [
      {
        title: '350 CADR',
        text: 'This is how efficiently the filter cleans the air. Any number over 200 is 👌.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: '350 CADR img',
      },
      {
        title: 'H13 HEPA layer',
        text: 'Filtering superfine particles, including dust, dander, and indoor/outdoor pollution. 99.6% efficiency.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: 'H13 HEPA layer ',
      },
      {
        inner: true,
        imgSrc: `${sock}`,
        imgWebpSrc: `${sockWebp}`,
        imgAlt: 'sock ',
      },
    ],
  },
  HW: {
    intro: {
      title: 'Get protection from your new pad.',
      text: 'A classic HEPA filter can’t eliminate the gases floating through a freshly revamped home. Mila’s super-engineered filter can.',
    },
    sliderItems: [
      {
        inner: true,
        imgSrc: `${wcfV2}`,
        imgWebpSrc: `${wcfV2Webp}`,
        imgAlt: 'wcgv1 ',
      },
      {
        title: 'H12 HEPA layer',
        text: 'Filtering fine particles 0.3 micrometers or larger. 99.6% efficiency.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: 'H12 HEPA layer ',
      },
      {
        title: '300 CADR',
        text: 'This is how efficiently the filter cleans the air. Any number over 200 is 👌.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: '350 CADR img',
      },
    ],
  },
  MB: {
    intro: {
      title: 'Say hello to your prenatal air protector.',
      text: 'Relying on a traditional HEPA filter won’t safeguard expectant mothers from VOCs and odors.',
    },
    sliderItems: [
      {
        title: '245 CADR',
        text: 'This is how efficiently the filter cleans the air. Any number over 200 is 👌.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: '245 CADR img',
      },
      {
        title: 'H14 HEPA layer',
        text: 'Filtering superfine particles, 99.995% efficiency.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: 'H14 HEPA layer ',
      },
      {
        inner: true,
        imgSrc: `${wcfV1}`,
        imgWebpSrc: `${wcfV1Webp}`,
        imgAlt: 'wcfV1 ',
      },
    ],
  },
  OR: {
    intro: {
      title: 'Protection from ALL the things.',
      text: 'The mother of all filters, this one combats anything and everything that could be lurking in your air.',
    },
    sliderItems: [
      {
        title: '254 CADR',
        text: 'This is how efficiently the filter cleans the air. Any number over 200 is 👌.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: '254 CADR img',
      },
      {
        title: 'H14 HEPA layer',
        text: 'Filtering superfine particles, including dust and indoor/outdoor pollution. 99.995% efficiency.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: 'H14 HEPA layer ',
      },
      {
        inner: true,
        imgSrc: `${wcfV2}`,
        imgWebpSrc: `${wcfV2Webp}`,
        imgAlt: 'wcfV2 ',
      },
    ],
  },
  RP: {
    intro: {
      title: 'Protection for (and from) your sweet, stinky kids.',
      text:
        'Designed with high-grade HEPA and a hefty carbon insert, The Rookie Parent effectively cleans the air '
        + 'your littles breathe (it can’t clean their toys off the floor though ... sorry).',
    },
    sliderItems: [
      {
        title: '364 CADR',
        text: 'This is how efficiently the filter cleans the air. Any number over 200 is 👌.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: '364 CADR img',
      },
      {
        title: 'H12 HEPA layer',
        text: 'Filtering superfine particles, including dust and indoor/outdoor pollution. 99.6% efficiency.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: 'H14 HEPA layer ',
      },
      {
        inner: true,
        imgSrc: `${hwCarbon}`,
        imgWebpSrc: `${hwCarbonWebp}`,
        imgAlt: 'pc Carbon ',
      },
    ],
  },
  PC: {
    intro: {
      title: 'Stay safe from interpersonal pollution.',
      text: 'Traditional HEPA filters can’t eliminate the negative vibes that pop up in a shared home. But Mila’s super-engineered filter can.',
    },
    sliderItems: [
      {
        title: 'Crushed amethyst crystal 💎',
        text: 'Nature’s absorber of negativity, woven into a granulated carbon filter.',
        imgSrc: `${pcCarbon}`,
        imgWebpSrc: `${pcCarbonWebp}`,
        imgAlt: 'Crushed amethyst crystal',
      },
      {
        title: 'H17 HEPA 💜',
        text: 'Filtering fine emotions 0.17 micrometers or larger, at 98.2% efficiency.',
        imgSrc: `${hepa}`,
        imgWebpSrc: `${hepaWebp}`,
        imgAlt: 'H17 HEPA layer ',
      },
      {
        title: 'Citrus aroma 🍋',
        text: 'The scents of orange, grapefruit, and lemon to uplift.',
        imgSrc: `${wcfV1}`,
        imgWebpSrc: `${wcfV1Webp}`,
        imgAlt: 'Citrus aroma img ',
      },
      {
        title: 'Giant smiley face',
        text: 'According to science, you should be smiling just looking at this thing.',
        imgSrc: `${wcfV2}`,
        imgWebpSrc: `${wcfV2Webp}`,
        imgAlt: 'Giant smiley face img ',
      },
    ],
  },
};

export default filterDetailStatic;
