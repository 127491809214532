import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import { CURRENCY_SIGN, FILTER_TYPES_STANDALONE } from '../../shared/enums';
import { setIsPeacekeeper } from '../features/root/rootSlice';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';

const FilterBillboard = loadable(() => import('../components/filter/FilterBillboard'));
const FilterCard = loadable(() => import('../components/filter/FilterCard'));
const FilterDetail = loadable(() => import('../components/filter/FilterDetail'));
const FilterFeature = loadable(() => import('../components/filter/FilterFeature'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const Peacekeeper = props => {
  const {
    route,
    staticContext,
  } = props;

  const prices = {
    US: { unit: 349, filter: 69 },
    CA: { unit: 479, filter: 86 },
    SG: { unit: 475, filter: 94 },
    GB: { unit: 309, filter: 60 },
    AU: { unit: 499, filter: 100 },
    EU: { unit: 349, filter: 69 },
  };

  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const sign = CURRENCY_SIGN[localization.region] || CURRENCY_SIGN.US;
  const { unit, filter } = prices[localization.region];

  useEffect(() => {
    dispatch(setIsPeacekeeper(true));
    return () => {
      dispatch(setIsPeacekeeper(false));
    };
  }, []);

  staticContext.bodyClass = 'filter-single peacekeeper-body';

  const page = Object.keys(FILTER_TYPES_STANDALONE)
    .find(key => FILTER_TYPES_STANDALONE[key] === 'peacekeeper');

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
        <meta
          name='description'
          content='Bad juju? We got you you. Designed to reduce hostility in the home, our newest filter targets negative energy in the air that emits from perturbed family members and housemates.'
        />
        <meta name='robots' content='noindex' />
        <meta name='robots' content='nofollow' />
      </Helmet>
      <div className='filter-single__wrapper' id={`${FILTER_TYPES_STANDALONE.PC}-modal`}>
        <FilterBillboard fallback={<FallbackPlaceholder />} page={page} />
        <div className='filter-content'>
          <div className='filter-order'>
            <div className='container'>
              <div className='filter-order__card' style={{ visibility: 'visible' }}>
                <div className='filter-order__card-primary'>
                  <h3 className='filter-order__card-title'>
                    <strong>Purchase Mila</strong>
                    {' '}
                    for just
                  </h3>
                  <p className='filter-order__card-price'>
                    <span className='filter-order__price-amount'>
                      <span className='currency-symbol'>{sign}</span>
                      {unit}
                    </span>
                    Per unit
                  </p>
                  <svg className='icon icon-plus' style={{ color: '#FFC554' }}><use xlinkHref='#icon-plus' /></svg>
                </div>
                <div className='filter-order__card-secondary'>
                  <h3 className='filter-order__card-title'>
                    <strong className='for-desktop'>Add The Peacekeeper filter</strong>
                    <strong className='for-phone'>Keep the love in the air with The Peacekeeper.</strong>
                  </h3>
                  <p className='filter-order__card-price'>
                    <span className='filter-order__price-amount'>
                      <span className='currency-symbol'>{sign}</span>
                      {filter}
                    </span>
                  </p>
                  <p className='filter-order__note'>
                    Or
                    {' '}
                    <strong style={{ color: '#FFC554' }}>FREE</strong>
                    {' '}
                    when you subscribe to Auto-Refill
                  </p>
                  <div className='filter-order__button filter-order__button--inside'>
                    <Link to='/lp/gotcha' className='btn btn--order btn--fullwidth' style={{ backgroundColor: '#FFC554', borderColor: '#FFC554' }}>Shop Now</Link>
                  </div>
                </div>
                <div className='filter-order__button filter-order__button--outside'>
                  <Link to='/lp/gotcha' className='btn btn--order btn--fullwidth' style={{ backgroundColor: '#FFC554', borderColor: '#FFC554' }}>Shop Now</Link>
                </div>
              </div>
              <div className='filter-order__intro'>
                <div className='filter-order__content'>
                  <h3 className='filter-order__intro-title'>Keep the air relaxed and drama free.</h3>
                  <p>Bad juju builds up fast. One day everyone’s getting along just fine, the next day your partner threatens to leave over the way you loaded the dishwasher.</p>
                  <p>The Peacekeeper will protect your home from the accumulation of sneaky bad vibes like these:</p>
                </div>
              </div>
            </div>
          </div>
          <FilterFeature page={page} />
          <FilterDetail page={page} />
          <FilterCard page='PC' />
        </div>
      </div>
    </>
  );
};

Peacekeeper.defaultProps = {
  staticContext: {},
};

export default { component: Peacekeeper };
