import basicBreatherHero from 'assets/images/basic-breather-hero.png';
import bigSneezeHero from 'assets/images/big-sneeze-hero.png';
import critterCuddlerHero from 'assets/images/critter-cuddler-hero.png';
import homeWreckerModal from 'assets/images/home-wrecker-modal.png';
import overreactorHero from 'assets/images/overreactor-hero-image.png';
import rookieHero from 'assets/images/rookie-hero.png';
import mamaHero from 'assets/images/mama-hero.png';
import PeacekeeperHero from 'assets/images/peacekeeper-hero.png';

import baby from 'assets/images/baby.png';
import sneezingFace from 'assets/images/sneezing-face.png';
import construction from 'assets/images/construction.png';
import dogFace from 'assets/images/dog-face.png';
import woman from 'assets/images/woman.png';
import leafFlutteringInWind from 'assets/images/leaf-fluttering-in-wind.png';
import explodingHead from 'assets/images/exploding-head.png';
import PeacekeeperIcon from 'assets/images/peacekeeper-icon.png';

/**
 * @type {{BB: {titleImage, mediaImage, backgroundColor: string, text: string, type: string}, CC: {titleImage, mediaImage, backgroundColor: string, text: string, type: string}, BS: {titleImage, mediaImage, backgroundColor: string, text: string, type: string}, OR: {titleImage, mediaImage, backgroundColor: string, text: string, type: string}, MB: {titleImage, mediaImage, backgroundColor: string, text: string, type: string}, RP: {titleImage, mediaImage, backgroundColor: string, text: string, type: string}, HW: {titleImage, mediaImage, backgroundColor: string, text: string, type: string}, PC: {titleImage, mediaImage, backgroundColor: string, text: string, type: string}}}
 */
const filterBillboardStatic = {
  BB: {
    type: 'Basic Breather',
    titleImage: leafFlutteringInWind,
    mediaImage: basicBreatherHero,
    text: 'Our everyday filter designed for those who don’t need any bells and whistles—just the high-performing essentials.',
    backgroundColor: '#3E4649',
  },
  BS: {
    type: 'Big Sneeze',
    titleImage: sneezingFace,
    mediaImage: bigSneezeHero,
    text: 'Win the battle against allergens with the filter designed to protect you from being permanently attached to the tissue box.',
    backgroundColor: '#FFCA0C',
  },
  CC: {
    type: 'Critter Cuddler',
    mediaImage: critterCuddlerHero,
    titleImage: dogFace,
    text: 'Designed for pet parents, this HEPA + heavy-duty carbon filter removes pet dander and odors to keep your place free of animal funk and allergens.',
    backgroundColor: '#F42D53',
  },
  HW: {
    type: 'Home Wrecker',
    titleImage: construction,
    mediaImage: homeWreckerModal,
    text: 'Designed for the fixer-upper, this unique air filter targets the harmful chemicals that come with home renovations.',
    backgroundColor: '#FC830F',
  },
  MB: {
    type: 'Mama-to-Be',
    titleImage: woman,
    mediaImage: mamaHero,
    text: 'Breathing for two? Meet the mother of all air filters, ready to go to battle to protect you and your growing babe.',
    backgroundColor: '#1CE4D5',
  },
  OR: {
    type: 'Overreactor',
    titleImage: explodingHead,
    mediaImage: overreactorHero,
    text: 'Odors. Dust. Pollution. VOCs. Keep your room protected from just about everything with the most neurotic filter around.',
    backgroundColor: '#0A56EA',
  },
  RP: {
    type: 'Rookie Parent',
    titleImage: baby,
    mediaImage: rookieHero,
    text: 'Protect your little ones (and your own peace of mind) with the filter specially designed for young families.',
    backgroundColor: '#8551F0',
  },

  PC: {
    type: 'Peacekeeper',
    titleImage: PeacekeeperIcon,
    mediaImage: PeacekeeperHero,
    text: 'Designed to reduce hostility in the home, our newest filter targets negative energy that emits from perturbed family members and housemates.',
    backgroundColor: '#FFC554',
  },
};

export default filterBillboardStatic;
