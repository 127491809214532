import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';
import { supportFaqStatic } from '../static-data';

const HeroSupport = loadable(() => import('../components/support/HeroSupport'));
const InstallBlock = loadable(() => import('../components/support/InstallBlock'));
const UnitSlider = loadable(() => import('../components/support/UnitSlider'));
const SupportFaq = loadable(() => import('../components/support/SupportFaq'));
const DownloadBlock = loadable(() => import('../components/support/DownloadBlock'));
const ContactBlock = loadable(() => import('../components/support/ContactBlock'));

const Support = props => {
  const { route } = props;

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <HeroSupport fallback={<FallbackPlaceholder />} />
      <InstallBlock />
      <UnitSlider />
      <SupportFaq {...supportFaqStatic} />
      <DownloadBlock />
      <ContactBlock />
    </>
  );
};

export default { component: Support };
