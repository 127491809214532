import React from 'react';
import { Helmet } from 'react-helmet-async';

import flagUkraine from 'assets/images/flag-ukraine.jpg';

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
const StandWithUkraine = props => (
  <div>
    <Helmet>
      <title>{props?.route?.meta?.title}</title>
    </Helmet>
    <section className='hero-image' style={{ backgroundImage: `url(${flagUkraine})` }} />
    <section className='default-content default-content--large'>
      <div className='default-content--wrapper'>
        <div className='container'>
          <h1 className='default-content__title'>
            The Mila fam
            <br />
            {' '}
            #StandsWithUkraine 🇺🇦
          </h1>
          <div className='default-content__inner'>
            <div className='default-content__description'>
              <p>Dear fellow air breathers,</p>
              <p>
                Like so many of you around the world, we’re deeply concerned by the escalating tensions and attacks
                taking place in Ukraine. What you may not know is that six members of the Mila squad are based there,
                in Kharkiv—the target of some of the most intense attacks and bombings.
              </p>
              <p>
                The good news is that all of our Mila squad members and their families continue to remain safe and in
                good spirits. Although they have fled their homes and are now scattered about the country, we are in
                touch with them daily and are doing all we can to support them.
              </p>
              <p>
                That includes reaching out to you, our extended Mila fam. If you would like to support our crew with
                a personal message, feel free to
                {' '}
                <strong>
                  <a href='https://bit.ly/milajamboard' target='_blank' rel='noreferrer'>
                    add a
                    note here
                  </a>
                </strong>
                . In addition, if you would like to make a contribution to help all
                Ukrainians, here are some great opportunities:
              </p>
              <ul>
                <li>
                  Donate to the
                  {' '}
                  <strong>
                    <a
                      href='https://donate.redcrossredcrescent.org/ua/donate/~my-donation'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Red Cross Ukraine Emergency Appeal fund
                    </a>
                  </strong>
                </li>
                <li>
                  Donate to
                  {' '}
                  <strong><a href='https://novaukraine.org/' target='_blank' rel='noreferrer'>Nova Ukraine</a></strong>
                </li>
                <li>
                  <strong>
                    <a href='https://milamarketing.typeform.com/to/N7wSGCbQ' target='_blank' rel='noreferrer'>
                      Let us
                      know
                    </a>
                  </strong>
                  {' '}
                  if you know of anyone offering housing to Ukrainian refugees at this time — Mila
                  will be donating 100 Mila Air purifier units to European families hosting refugees. While it’s
                  difficult for anyone to breathe easy right now, we hope a home filled with clean air will help them
                  find some comfort.
                </li>
              </ul>
              <p>
                At Mila, you likely know us as ❤️ not 🥊, and we’re heartbroken to even have to write this note in
                the first place. Our Ukrainian squad members are a core part of the Mila team and they continue to
                inspire us with their strength, resilience, and unyielding optimism in the face of this unthinkable
                violence. We stand with all Ukrainians and all those affected by these actions. #MilaCares
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default
{
  component: StandWithUkraine,
};
