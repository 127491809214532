import React from 'react';
import { FILTER_TYPES_STANDALONE } from '../../shared/enums';
import FilterStandalone from './FilterStandalone';
import NotFound from './NotFound';
import { fetchRegionalPrices } from '../features/regionalPrices/regionalPricesSlice';

const FilterStandaloneWraper = props => {
  const {
    match: {
      params: { type },
    },
  } = props;

  const Wraper = Object.values(FILTER_TYPES_STANDALONE).includes(type) ? FilterStandalone.component : NotFound.component;

  return (
    <Wraper {...props} />
  );
};

const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchRegionalPrices(data),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default
{
  component: FilterStandaloneWraper, loadData,
};
