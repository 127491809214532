import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';

import appRoomScreenshotVideo from 'assets/images/app-rooms-video.mp4';

import {
  fetchRegionalPrices,
  selectRegionalPricesData, selectRegionalPricesError,
  selectRegionalPricesStatus,
} from '../features/regionalPrices/regionalPricesSlice';
import { STATUS } from '../../shared/enums';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import { appWidgetsDayStatic, appWidgetsNightStatic } from '../static-data';

const OrderBlockAlt = loadable(() => import('../components/app-update/OrderBlockAlt'));
const AppHero = loadable(() => import('../components/app-page/AppHero'));
const AppFeatures = loadable(() => import('../components/app-page/AppFeatures'));
const AppWidgets = loadable(() => import('../components/app-page/AppWidgets'));
const AppInfo = loadable(() => import('../components/app-page/AppInfo'));

const FadeIn = loadable(() => import('../components/common/FadeIn'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const AppPage = props => {
  const { route } = props;

  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const regionalPrices = useSelector(selectRegionalPricesData);
  const status = useSelector(selectRegionalPricesStatus);
  const error = useSelector(selectRegionalPricesError);

  useEffect(() => {
    if (status !== STATUS.PREFETCHED) {
      dispatch(fetchRegionalPrices(localization));
    }
    return null;
  }, []);
  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
      </Helmet>
      <main className='main'>
        <AppHero />
        <AppFeatures />
        <AppWidgets widgetsDataStatic={appWidgetsDayStatic} themeType='light' />
        <AppWidgets widgetsDataStatic={appWidgetsNightStatic} themeType='dark' />
        <section className='update-info update-info--default'>
          <div className='container'>
            <div className='update-info__grid'>
              <div className='update-info__content'>
                <h2 className='update-info__title'>Keep tabs on every Mila in every room.</h2>
                <div className='update-info__description'>
                  <p>
                    Is the bacon you’re cooking in the kitchen affecting the kids’ bedroom? How’s the AQI in the bedroom where you have the windows open? Find out any time with a quick glance at your Milas around the house.
                  </p>
                </div>
              </div>
              <figure className='update-info__media'>
                <FadeIn>
                  <video
                    playsInline
                    webkit-playsinline='true'
                    autoPlay
                    muted
                    loop
                  >
                    <source src={appRoomScreenshotVideo} type='video/mp4' />
                  </video>
                </FadeIn>
              </figure>
            </div>
          </div>
        </section>
        <div className='quote-block'>
          <div className='quote-block__wrap'>
            <div className='quote-block__card'>
              <div className='quote-block__card-inner'>
                <blockquote className='quote-block__quote'>
                  <p>
                    The app is easy to use
                    {' '}
                    <br />
                    and has many thoughtful, and humorous touches. They also make it easy to understand all the different air quality metrics, that I never really understood before.
                  </p>
                  <cite className='for-desktop'>
                    Andy L. — Fremont, CA
                  </cite>
                  <cite className='for-phone'>
                    - Andy L.
                  </cite>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        <AppInfo />
        <section className='update-cfa update-cfa--alt'>
          <div className='container'>
            <h2 className='update-cfa__title'>
              And it’s always getting smarter.
            </h2>
            <div className='update-cfa__description'>
              <p>
                We’re constantly improving our app to help make the world less airblivious. Have an idea to contribute?
                {' '}
                <a href='mailto:support@milacares.com?subject=I have a suggestion'>Let us know</a>
                {' '}
                <br />
                — we’re listening.
              </p>
            </div>
            <h3 className='update-cfa__subtitle'>
              Check out the latest
              {' '}
              <br />
              updates.
            </h3>
            <p className='update-cfa__sub-description'>Update 1.13 features new iOS widgets and a new mode. Learn more. </p>
            <Link to='/support' className='update-cfa__link'>
              Visit our change log
            </Link>
          </div>
        </section>
        {regionalPrices && (
          <OrderBlockAlt
            error={error}
            localization={localization}
            regionalPrices={regionalPrices}
            title='Get Mila (and the awesome app) today.'
            description={`She’s backed by a 30-day, no-questions-asked guarantee. You’ve got nothing to
           lose but crappy air. Order your Mila today.`}
            altStyle='order-alt--style2'
          />
        )}
      </main>
    </>
  );
};
const loadData = store => {
  const { localization: { data }} = store.getState();
  const actions = [
    fetchRegionalPrices(data),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default { component: AppPage, loadData };
