const filterPricesStatic = {
  HW: {
    US: { base: 349, sales: 297 },
  },
  RP: {
    US: { base: 349, sales: 297 },
  },
};

export default filterPricesStatic;
