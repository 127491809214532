/**
 * @type {Readonly<{SIGINT: string, SIGTERM: string}>}
 */
const SIGNALS = Object.freeze({
  SIGINT: 'SIGINT',
  SIGTERM: 'SIGTERM',
});

/**
 * @type {Readonly<{LOVING_CAT: string, GRAWLIX_FACE: string, ROCKET: string, OK_HAND: string, WEARY_CAT: string, SPARKLES: string, HANDSHAKE: string, HUGGING_FACE: string, ONCOMING_FIST: string, FLEXED_BICEPS: string, NAUSEATED_FACE: string, BRAIN: string}>}
 */

const EMOJI = Object.freeze({
  ROCKET: '🚀',
  OK_HAND: '👌',
  WEARY_CAT: '🙀',
  LOVING_CAT: '😻',
  HANDSHAKE: '🤝',
  ONCOMING_FIST: '👊',
  HUGGING_FACE: '🤗',
  NAUSEATED_FACE: '🤢',
  GRAWLIX_FACE: '🤬',
  SPARKLES: '✨',
  BRAIN: '🧠',
  FLEXED_BICEPS: '💪',
  RED_HEART: '❤️',
  Heart_Shaped_Eyes: '😍',
});

/**
 * @type {Readonly<{EU: string, SG: string, GB: string, CA: string, US: string}>}
 */

const SUPPORTED_REGIONS = Object.freeze({
  US: 'US',
  CA: 'CA',
  SG: 'SG',
  GB: 'GB',
  AU: 'AU',
  EU: 'EU',
});

/**
 * @type {Readonly<{DE: string, BE: string, PT: string, DK: string, LU: string, IT: string, FR: string, HU: string, ES: string, SE: string, AT: string, CZ: string, IE: string, PL: string, RO: string, NL: string}>}
 */
const SUPPORTED_EU_REGIONS = Object.freeze({
  AT: 'AT',
  BE: 'BE',
  FR: 'FR',
  DE: 'DE',
  IE: 'IE',
  IT: 'IT',
  LU: 'LU',
  NL: 'NL',
  PT: 'PT',
  ES: 'ES',
  DK: 'DK',
  SE: 'SE',
  CZ: 'CZ',
  HU: 'HU',
  PL: 'PL',
  RO: 'RO',
});

/**
 * @type {Readonly<{SG: string, AU: string, GB: string, CA: string, US: string}>}
 */
const CURRENCY_MAPPING = Object.freeze({
  US: 'USD',
  CA: 'CAD',
  SG: 'SGD',
  GB: 'GBP',
  AU: 'AUD',
  EU: 'EUR',
});

/**
 * @type {Readonly<{EU: string, AU: string, SG: string, GB: string, US: string, CA: string}>}
 */
const CURRENCY_SIGN = Object.freeze({
  US: '$',
  CA: '$',
  GB: '£',
  AU: '$',
  SG: '$',
  EU: '€',
});

/**
 * @type {Readonly<{EU: number, AU: number, SG: number, GB: number, US: number, CA: number}>}
 */
const DYSON_UNIT_PRICES = Object.freeze({
  US: 599,
  CA: 699,
  GB: 549,
  AU: 800,
  SG: 815,
  EU: 549,
});

/**
 * @type {Readonly<{EU: number, AU: number, SG: number, GB: number, US: number, CA: number}>}
 */
const MOLEKULE_UNIT_PRICES = Object.freeze({
  US: 799,
  CA: 749,
  GB: 610,
  AU: 1067,
  SG: 1087,
  EU: 732,
});

/**
 * @type {number}
 */
const DYSON_FILTER_PRICE_USD = 150;

/**
 * @type {number}
 */
const MOLEKULE_FILTER_PRICE_USD = 140;

/**
 * @type {Readonly<{MILA_UNIT: number, ROOKIE_PARENT: number, BIG_SNEEZE: number, CRITTER_CUDDLER: number, HOME_WRECKER: number, BASIC_BREATHER: number, MAMA_TO_BE: number, OVERREACTOR: number}>}
 */
const PRODUCTS_ID_MAPPING = Object.freeze({
  MILA_UNIT: 1,
  BASIC_BREATHER: 4,
  BIG_SNEEZE: 5,
  ROOKIE_PARENT: 6,
  CRITTER_CUDDLER: 7,
  HOME_WRECKER: 8,
  MAMA_TO_BE: 9,
  OVERREACTOR: 10,
});

/**
 * @type {Readonly<{MILA_UNIT: string, ROOKIE_PARENT: string, BIG_SNEEZE: string, CRITTER_CUDDLER: string, HOME_WRECKER: string, BASIC_BREATHER: string, MAMA_TO_BE: string, OVERREACTOR: string, PEACEKEEPER: string}>}
 */
const PRODUCT_VARIANT_ABBREVIATIONS = Object.freeze({
  MILA_UNIT: 'UNIT',
  BASIC_BREATHER: 'BB',
  BIG_SNEEZE: 'BS',
  ROOKIE_PARENT: 'RP',
  CRITTER_CUDDLER: 'CC',
  HOME_WRECKER: 'HW',
  MAMA_TO_BE: 'MB',
  OVERREACTOR: 'OR',
  PEACEKEEPER: 'PC',
});

/**
 * @type {Readonly<{BB: string, CC: string, BS: string, OR: string, MB: string, RP: string, HW: string}>}
 */
const FILTER_TYPES = Object.freeze({
  BB: 'basic-breather',
  BS: 'big-sneeze',
  RP: 'rookie-parent',
  CC: 'critter-cuddler',
  HW: 'home-wrecker',
  MB: 'mama-to-be',
  OR: 'overreactor',
});

/**
 * @type {Readonly<{BB: string, CC: string, BS: string, OR: string, MB: string, RP: string, HW: string}>}
 */
const FILTER_NAMES = Object.freeze({
  BB: 'Basic Breather',
  BS: 'Big Sneeze',
  RP: 'Rookie Parent',
  CC: 'Critter Cuddler',
  HW: 'Home Wrecker',
  MB: 'Mama-to-Be',
  OR: 'Overreactor',
});

/**
 * @type {Readonly<{BB: string, CC: string, BS: string, OR: string, PC: string, MB: string, RP: string, HW: string}>}
 */
const FILTER_TYPES_STANDALONE = Object.freeze({
  BB: 'basic-breather-filter',
  BS: 'big-sneeze-filter',
  RP: 'rookie-parent-filter',
  CC: 'critter-cuddler-filter',
  HW: 'home-wrecker-filter',
  MB: 'mama-to-be-filter',
  OR: 'overreactor-filter',
  PC: 'peacekeeper',
});

/**
 * @type {Readonly<{BB: string, CC: string, BS: string, OR: string, PC: string, MB: string, RP: string, HW: string}>}
 */
const FILTER_NAMES_STANDALONE = Object.freeze({
  BB: 'Basic Breather filter',
  BS: 'Big Sneeze filter',
  RP: 'Rookie Parent filter',
  CC: 'Critter Cuddler filter',
  HW: 'Home Wrecker filter',
  MB: 'Mama-to-Be filter',
  OR: 'Overreactor filter',
  PC: 'Peacekeeper',
});

/**
 * @type {Readonly<[string]>}
 */
const PAGES_WITHOUT_ZENDESK = Object.freeze([ '/careers' ]);

/**
 * @type {string[]}
 */
const PAGES_WITHOUT_NAV = Object.freeze([ '/sneakpeek', '/sneakpeek/' ]);

/**
 * @type {string[]}
 */
const RELOAD_DOCUMENT_REQUIRED = Object.freeze([ '/referral' ]);

/**
 * @type {Readonly<{LOADING: string, FAILED: string, IDLE: string, PREFETCHED: string, SUCCEEDED: string}>}
 */

const STATUS = Object.freeze({
  IDLE: 'idle',
  LOADING: 'loading',
  PREFETCHED: 'prefetched',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
});

export {
  SIGNALS,
  EMOJI,
  SUPPORTED_REGIONS,
  SUPPORTED_EU_REGIONS,
  CURRENCY_MAPPING,
  CURRENCY_SIGN,
  DYSON_UNIT_PRICES,
  MOLEKULE_UNIT_PRICES,
  DYSON_FILTER_PRICE_USD,
  MOLEKULE_FILTER_PRICE_USD,
  PRODUCTS_ID_MAPPING,
  PRODUCT_VARIANT_ABBREVIATIONS,
  FILTER_TYPES,
  FILTER_TYPES_STANDALONE,
  FILTER_NAMES,
  FILTER_NAMES_STANDALONE,
  PAGES_WITHOUT_ZENDESK,
  PAGES_WITHOUT_NAV,
  RELOAD_DOCUMENT_REQUIRED,
  STATUS,
};
