import React from 'react';
import { Helmet } from 'react-helmet-async';
import mediaImage from 'assets/images/gottcha-hero.png';
import loadable from '@loadable/component';

const FilterCard = loadable(() => import('../components/filter/FilterCard'));
const LazyImg = loadable(() => import('../components/common/LazyImg'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Gottcha = props => {
  const {
    route,
    staticContext,
  } = props;

  staticContext.bodyClass = 'filter-single peacekeeper-body';
  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
        <meta name='robots' content='noindex' />
        <meta name='robots' content='nofollow' />
      </Helmet>
      <div className='filter-single__wrapper' id='gottcha-modal'>
        <div className='filter-billboard filter-billboard--landscape-image' style={{ backgroundColor: '#FFC554' }}>
          <div className='filter-billboard__wrap'>
            <div className='filter-billboard__content'>
              <a href='/filters' className='btn btn--outline'>
                <svg className='icon icon-left-arrow'>
                  <use xlinkHref='#icon-left-arrow' />
                </svg>
                Go back
              </a>
              <h1 className='filter-billboard__title'>
                Psyyyyyyych!
              </h1>
              <h2 className='filter-billboard__sub-title'>
                We got you.
                {' '}
                <br />
                {' '}
                Happy April Fool’s Day.
              </h2>
              <p>(Although we *are* quietly working on making this filter for real, because let’s be honest—we all need it.)</p>
            </div>
            <figure className='filter-billboard__media'>
              <LazyImg src={mediaImage} alt='Gottcha-hero' />
            </figure>
          </div>
        </div>
        <div className='filter-content'>
          <div className='filter-shop'>
            <div className='filter-shop__content'>
              <p className='filter-shop__leadtext'>Here’s a gift for making it this far in the gag:</p>
              <h2 className='filter-shop__heading'>
                Save $33.33 on a new Mila unit with code
                {' '}
                <strong>SERENITYNOW</strong>
              </h2>
              <div className='filter-shop__details'>
                <p>Why all the 3’s? It’s considered the number of harmony and understanding. 😇</p>
                <p>Use this code to restore tranquility in your air (by filtering the airborne baddies we can actually control) or feel free to share it with a friend. 👯</p>
              </div>
              <div className='filter-shop__button'>
                <a href='/buy' className='btn btn--order' style={{ backgroundColor: '#FFCA0C', borderColor: '#FFCA0C' }}>Shop now</a>
              </div>
            </div>
          </div>
          <FilterCard page='GC' />
        </div>
      </div>
    </>
  );
};
Gottcha.defaultProps = {
  staticContext: {},
};

export default { component: Gottcha };
