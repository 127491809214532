import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';

export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const fetchBanners = createAsyncThunk('banners/fetchBanners', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/banners');
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {setBannersIdle: *, clearBanners: *}, string>}
 */
export const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    clearBanners: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
    setBannersIdle: state => {
      state.status = initialState.status;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBanners.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
        state.error = initialState.error;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearBanners, setBannersIdle } = bannersSlice.actions;

export const selectBannersData = state => state.banners.data;
export const selectBannersStatus = state => state.banners.status;
export const selectBannersError = state => state.banners.error;

export default bannersSlice.reducer;
