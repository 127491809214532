import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * @param route
 * @returns {JSX.Element}
 * @constructor
 */
const Referral = ({ route }) => (
  <>
    <Helmet>
      <title>{route?.meta?.title}</title>
      <script src={YOTPO_SRC} async />
    </Helmet>
    <div className='yotpo-widget-instance' data-yotpo-instance-id={YOTPO_ID} />
  </>
);

export default { component: Referral };
